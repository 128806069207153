// REM mixin
.rem-px(@property; @sizeValue) {

    @pxValue: @sizeValue;
    @remValue: (@sizeValue / @fontSizeStandard);
    @{property}: ~"@{pxValue}px";
    @{property}: ~"@{remValue}rem";
  }

@fontSizeStandard: 15;

.type-regular() {
  font-family: 'NotoSans', 'NeueHaasUnicaPro', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

.type-italic() {
  font-family: 'NotoSans', 'NeueHaasUnicaPro', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
}

.type-bold() {
  font-family: 'NotoSans', 'NeueHaasUnicaPro', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
}
/*
.type-black() {
  font-family: 'maisonneue-demi', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}*/

.type-secondary() {
  font-family: 'NotoSans', 'NeueHaasUnicaPro', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.type-headings() {
  font-family: 'NotoSerif', 'CaslonTwoTwentyFour-Bold', Georgia, Times, 'Times New Roman', serif;
  font-style: normal;
  font-weight: normal;
}


// Large fonts
.font-l3() {
  .rem-px(font-size,48);
  .rem-px(line-height,56);
}

.font-l2() {
  .rem-px(font-size,40);
  .rem-px(line-height,48);
}

.font-l1() {
  .rem-px(font-size,32);
  //.rem-px(line-height,70);
}

// Medium fonts
.font-m3() {
  .rem-px(font-size,28);
  .rem-px(line-height,32);
}

.font-m2() {
  .rem-px(font-size,22);
  .rem-px(line-height,16);
}

.font-m1() {
  .rem-px(font-size,18);
  .rem-px(line-height,22);
}

// Small fonts
.font-s3() {
  // This sets the baseline for the layout but gets overridden per template
  // Vertical rhythm is all multiples of 4
  .rem-px(font-size,14);
  .rem-px(line-height,20);
}

.font-s2() {
  .rem-px(font-size,12);
  .rem-px(line-height,18);
}

.font-s1() {
  .rem-px(font-size,10);
  .rem-px(line-height,16);
}


// ALT to be paired to type-secondary
// Because the typeface LORA reads larger than LATO
.font-s2-alt() {
  .rem-px(font-size,11);
  .rem-px(line-height,18);
}

.font-s1-alt() {
  .rem-px(font-size,9);
  .rem-px(line-height,18);
}