.toggle-wrapper {
  display: flex;
  align-items: center;
  margin-top: 1em;

  .toggle {
    input {
      max-height: 0;
      max-width: 0;
      opacity: 0;

      & + label {
        display: inline-block;
        position: relative;
        box-shadow: inset 0 0 0 1px #d5d5d5;
        text-indent: -5000px;
        height: 30px;
        width: 50px;
        border-radius: 15px;
        margin: 0;
        cursor: pointer;
      }

      & + label::before {
        content: "";
        position: absolute;
        display: block;
        height: 30px;
        width: 30px;
        top: 0;
        left: 0;
        border-radius: 15px;
        background: rgba(19, 191, 17, 0);
        .transition(0.25s ease-in-out);
      }

      & + label::after {
        content: "";
        position: absolute;
        display: block;
        height: 30px;
        width: 30px;
        top: 0;
        left: 0;
        border-radius: 15px;
        background: white;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
        .transition(0.25s ease-in-out);
      }

      &:checked + label::before {
        width: 50px;
        background: @color-light-green;
      }

      &:checked + label::after {
        left: 20px;
        box-shadow: inset 0 0 0 1px @color-light-green, 0 2px 4px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .toggle-label {
    flex: 1;
    margin-bottom: 0;
    margin-top: 4px;

    &.disabled {
      color: #d8d8d8;
    }
  }
}
