.open-inapp {
  background: #f6f6f6;
  padding: 15px 15px 12px 25px;
  display: flex;
  align-items: center;

  .text-link {
    color: #4a4a4a;
    font-size: 14px;
    flex: 1;
  }

  .view-link {
    color: #b50700;
    letter-spacing: 0.6px;
    font-weight: bold;
  }

  .app-icon {
    height: 50px;
    border-radius: 10px;
    margin-right: 10px;
    border: solid 1px #d5d5d5;
  }
}
