* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; // For some Androids
  .box-sizing(border-box);
}

html,
button,
input,
textarea,
select {
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: @base-font-size;
}

body {
  color: @color-dark-grey;
  font-size: @base-font-size; // This sets the baseline so we can use multiples of 4 & (r)ems
  min-height: -webkit-fill-available;
  .type-regular();
}

// Default type layout
h1,
h2,
h3,
h4,
h5,
h6 {
  color: @color-well;
  margin: 0;
  padding: 0;
  .type-headings();
}

h1 {
  .font-l1;
}

h2 {
  .font-m3;
}

h3 {
  .font-m2;
}

h4 {
  .font-m1;
}

h5 {
  color: @color-medium-rare;
  text-transform: uppercase;
  .font-s2();
  .type-bold();
}

h6 {
  color: @color-medium;
}

p {
  .font-s3;
}

sub,
sup {
  font-size: 0.8em;
}

sub {
  bottom: -0.2em;
}

sup {
  top: -0.2em;
}

b {
  .type-bold;
}

em {
  .type-italic;
}

a {
  color: @color-primary-red;
}

a,
a:active { //, a:focus
  outline: none;
}

input::-moz-focus-inner {
  border: 0;
}

ol,
ul {
  list-style: none;
  margin: none;
  padding: 0;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #bbb;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #bbb;
}

input::placeholder,
textarea::placeholder {
  color: #bbb;
}
