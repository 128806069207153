.user-settings,
.course-details {
  .panel {
    border: solid 1px #f1eadb;
    margin-bottom: 1em;

    .panel-heading {
      a {
        display: block;
        position: relative;
        padding: 12px 20px 10px;
        background: #fbf9f5;
        color: #594a4f;
        cursor: pointer;
        border-bottom: solid 1px #f1eadb;

        &::after {
          color: #cec2a8;
          position: absolute;
          right: 1em;
          top: 10px;
          content: "\2023";
          .transform(rotate(90deg));
        }

        &.collapsed {
          border-bottom: none;

          &::after {
            position: absolute;
            right: 1em;
            content: "\2023";
            transform: rotate(0) /* rtl: rotate(180deg) */;
          }
        }
      }
    }

    .panel-collapse {
      padding: 20px;
    }
  }
}
