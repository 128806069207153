.main-container {
  .course-list-container {
    position: fixed;
    opacity: 0;
    top: 60px;
    left: 0;
    // background: @color-dark-grey;
    background: #f6f2ea;
    height: 96%;
    width: 300px;
    z-index: 1050;
    box-sizing: border-box;
    padding-bottom: 1rem;
    overflow: auto;
    .translateX(-24rem);
    .transition(transform 0.5s, opacity 0.3s);

    &.visible {
      opacity: 1;
      .translateX(0);
    }

    .overlay {
      display: none;
    }

    &.offline {
      .overlay {
        display: flex;
        background: rgba(37, 37, 37, 0.9);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        color: white;
      }
    }

    .filter {
      background: #e2ddd1;
      border: none;
      color: #505050;

      &:focus {
        box-shadow: none;
        background: #dcd7cc;
      }
    }

    .filter-button {
      button:hover {
        background: #e2ddd1;
      }
    }

    .icon-x {
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 0.7rem;
      font-size: 12px;
      color: @color-brown;
    }

    h3 {
      // color: white;
      color: @color-dark-grey;
      font-size: 18px;
      padding-bottom: 11px;
      margin-bottom: 15px;
      margin-top: 0.5em;
      // border-bottom: solid 1px white;
      border-bottom: solid 1px #ddd9cc;
      .type-bold();

      .updated {
        width: 94%;

        &::after {
          font-family: 'icomoon', sans-serif !important;
          content: "\e90e";
          color: @color-primary-red;
          margin-left: 5px;
          font-size: 11px;
          position: absolute;
          margin-top: -6px;
        }
      }
    }

    .button-new {
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      border: solid 1px @color-medium;
      padding: 1.8rem 1rem 1.6rem;
      color: @color-medium;
      margin-bottom: 1rem;
      margin-top: 1rem;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.9rem;
      background: rgba(0, 0, 0, 0.1);

      &:hover {
        border-color: #bababa;
        color: #bababa;
      }

      &:active {
        border-color: @color-team-yellow;
        color: @color-team-yellow;
        background: rgba(255, 182, 55, 0.2);
      }
    }

    .archive {
      display: block;
      margin-top: 2em;
      margin-bottom: 2em;
      text-align: center;
      color: #989898;
      font-size: 0.9rem;

      &:hover {
        color: @color-team-yellow;
      }
    }
  }

  .course-bundle {
    &.disabled {
      opacity: 0.4;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.65;
      }
    }

    ul.course-list {
      overflow: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 0;
      justify-content: space-between;

      &.collapse {
        display: none;

        &.show {
          display: flex;
        }
      }

      li {
        width: 48%;
        margin-bottom: 1rem;
        // height: 300px;
        line-height: 1.2rem;
        font-size: 0.9rem;
        overflow: hidden;

        &.not-current {
          // transition: 0.2s;
          // opacity: 0.7;

          &:hover {
            // opacity: 0.9;
          }
        }

        .course-cover {
          background-position: center;
          background-size: cover;
          width: 100%;
          // .rem-px(height, 120);
          padding-bottom: 100%;
          cursor: pointer;
          border-radius: 4px;

          &.empty {
            // border: solid 1px #cecaba;
            background: #ece7db;
          }
        }

        .btn {
          margin-top: 0.5rem;
          margin-bottom: 0.7rem;
          width: 100%;
          padding: 8px 0 6px;

          &:hover {
            background: darken(@color-personal-blu, 8%);
            border: solid 1px darken(@color-personal-blu, 8%);
          }

          &.current {
            background: @color-primary-red;
            border: solid 1px @color-primary-red;
            color: white;

            &:hover {
              background: darken(@color-primary-red, 8%);
              border: solid 1px darken(@color-primary-red, 8%);
            }
          }
        }

        .course-title {
          color: @color-dark-grey;
          font-weight: bold;
          line-height: 1.4rem;
        }

        .course-subtitle {
          color: #636363;
          display: block;
        }
      }
    }
  }

  @-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
  }

  @-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
}

.templates-modal {
  ul.templates-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 !important;

    li {
      display: inline-block;
      line-height: 22px;
      margin-bottom: 2em;
      cursor: pointer;
      opacity: 0.2;
      .rem-px(width, 180);

      &:hover {
        opacity: 0.4;
      }

      &.visible {
        opacity: 1;
      }
    }

    .course-cover {
      background-position: center;
      background-size: cover;
      width: 100%;
      margin-bottom: 1em;
      // .rem-px(height, 180);
      padding-bottom: 100%;

      &.empty {
        // border: solid 1px #cecaba;
        background: #eae9e2;
      }
    }

    .course-title {
      font-weight: bold;
    }

    .course-subtitle {
      font-size: 0.9em;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .course-filter {
    top: 60px !important;
    width: 300px !important;
  }
}
