.inactivity-messaging {
  position: relative;

  h3 { margin: 1em 0; }

  .editable {
    margin-top: 0;

    &.error {
      border-color: @color-primary-red;
      background-color: #ffecea;
    }

    &:disabled {
      border-color: silver;
    }
  }

  .threedayspush,
  .fourdayspush,
  .subject-tooltip,
  .message-tooltip {
    white-space: normal;

    .tooltip-inner {
      max-width: 270px;
      // text-align: left;
    }
  }

  .inactivity-emails {
    padding-bottom: 2rem;
  }

  .toggle {
    input {
      max-height: 0;
      max-width: 0;
      opacity: 0;

      & + label {
        display: inline-block;
        position: relative;
        box-shadow: inset 0 0 0 1px #d5d5d5;
        text-indent: -5000px;
        height: 30px;
        width: 50px;
        border-radius: 15px;
        margin: 0;
        cursor: pointer;
      }

      & + label::before {
        content: "";
        position: absolute;
        display: block;
        height: 30px;
        width: 30px;
        top: 0;
        left: 0;
        border-radius: 15px;
        background: rgba(19, 191, 17, 0);
        .transition(0.25s ease-in-out);
      }

      & + label::after {
        content: "";
        position: absolute;
        display: block;
        height: 30px;
        width: 30px;
        top: 0;
        left: 0;
        border-radius: 15px;
        background: white;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
        .transition(0.25s ease-in-out);
      }

      &:checked + label::before {
        width: 50px;
        background: @color-light-green;
      }

      &:checked + label::after {
        left: 20px;
        box-shadow: inset 0 0 0 1px @color-light-green, 0 2px 4px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.main-content {
  .email-component {
    input {
      margin-bottom: 1rem;
    }

    textarea {
      height: 150px;
    }
  }
}
