.main-container,
.workbook {
  .current-session {
    .next-action-divider {
      margin: 45px 0 15px;
    }

    .next-action-text {
      text-align: center;
      color: white;
      font-size: 24px;
      font-family: 'NotoSerif', 'CaslonTwoTwentyFour-Bold', serif;
    }

    &.new {
      .next-action-text {
        @apply text-deepgray text-center text-lg font-noto;
      }
    }

    .no-session {
      background: white;
      margin-top: 10px;
      padding: 50px 20px 47px;
      text-align: center;

      .new-session {
        padding: 10px 20px 7px;
        background-color: @color-personal-blu;
        color: white;
        border-radius: 4px;
      }
    }

    .button-new {
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      padding: 2.5rem 3rem 2.3rem;
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 1em;
      border: solid 1px white;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.9rem;
      color: white;
      background: rgba(0, 0, 0, 0.1);

      &:hover {
        border-color: lighten(@color-team-yellow, 10%);
        color: lighten(@color-team-yellow, 10%);
      }

      &:active {
        border-color: @color-team-yellow;
        color: @color-team-yellow;
        background: rgba(255, 182, 55, 0.2);
      }
    }

    .edit-title {
      text-align: center;
      font-size: 1em;
      margin-top: -13px;
      height: auto;
      padding-bottom: 3px;
    }
  }

  .session-block {
    background: white;
    margin-bottom: 1rem;
    padding: 3rem 3rem 2rem;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    position: relative;

    @media (max-width: 767px) { padding: 1em 1.5em; }

    .session-buttons {
      .note-link {
        position: absolute;
        right: 1.5rem;
        top: 1.25em;
      }

      .session-removeUserStudy {
        cursor: pointer;
        font-size: 20px;
        position: absolute;
        left: 1.5rem;
        top: 1.25em;
      }
    }

    .note-link {
      color: @color-primary-red;
      text-decoration: none;
      .font-m2();
    }
  }

  .session-number {
    text-transform: uppercase;
    color: @color-brown;
    letter-spacing: 2px;
    overflow: hidden;
    height: 1.2rem;
    cursor: default;
    text-align: center;
    line-height: normal;

    .scroller {
      transition: transform 0.3s;
      transform: translateY(0);

      &:hover {
        transform: translateY(-1.3rem);
      }
    }
  }

  .fixed-session-number {
    // position: fixed;
    top: 20px;
    z-index: 99999999 !important;
    left: 50%;
    right: auto;
    transform: translateX(-24.5%);
    width: 600px;
    height: 20px;
    margin-left: -2px;
  }

  h1 {
    margin: 30px 0;
  }

  .session-description {
    line-height: 1.7rem;
  }

  .session-image-holder {
    width: 250px;
    margin: 0 auto 30px;
    position: relative;
    text-align: center;

    &.large {
      width: auto;
    }
  }

  .workbook-image {
    object-fit: cover;
    width: 250px;
    height: 250px;
    max-width: 100%;
    border-radius: 4px;
    border: solid 1px white;
    background-color: white;
    // .transition(.3s ease);

    &.large {
      cursor: zoom-out;
    }

    &.editable {
      border: solid 1px @color-team-yellow;
      padding: 0;
      cursor: pointer;

      &:disabled {
        color: #bdbdbd;
        border-color: lighten(@color-team-yellow, 20%);
      }
    }
  }

  .session-image {
    cursor: zoom-in;
  }

  .trash {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 40px;
    cursor: pointer;
  }

  .workbook-image {
    @media screen and (max-width: 500px) {
      float: none;
      display: block;
      margin: 0 auto 30px;
    }
  }
}

.get-app-widget-select {
  display: inline-block;
  width: 25%;
}

.get-app-widget-number {
  display: inline-block;
  width: 40%;
  margin-right: 2%;
  margin-left: 2%;
}

.continue-next-session img {
  transform: rotate(0deg) /* rtl: rotate(180deg) */;
}
