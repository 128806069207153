html,
body {
  height: 100%;
  --lh: 1.5rem;
}

body {
  background: url(/app/common/images/bg-blur-afternoon.jpg) no-repeat center center fixed;
  background-size: cover;

  .transition(background 3s linear);

  &.fast { .transition(background 1s linear); }

  &.morning {
    background-color: #74aeba;
    background-image: url(/app/common/images/bg-blur-morning.jpg);
  }

  &.lunch {
    background-color: #e6a07e;
    background-image: url(/app/common/images/bg-blur-lunch.jpg);
  }

  &.afternoon {
    background-color: #ac9b8f;
    background-image: url(/app/common/images/bg-blur-afternoon.jpg);
  }

  &.night {
    background-color: #303d6a;
    background-image: url(/app/common/images/bg-blur-night.jpg);
  }

  &.gnowbefy {
    background-color: #ab8395;
    background-image: url('/app/common/images/gnowbefy.jpg');
    .transition(background 1s linear);

    #next-action-divider-wrapper {
      display: none;
    }
  }
}

a {
  .transition(color, 0.5s);

  &:hover {
    text-decoration: none;
  }
}

#gnowbe-logo {
  width: 120px;
  height: 36px;
  opacity: 0.5;
  position: fixed;
  right: 20px;
  bottom: 60px;

  // &.animate {
  //   .animation(logo-shine .5s linear forwards);
  // }

  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #fff;
    opacity: 0;
    .animation(logo-in 0.5s linear forwards);
    .transform-origin(50 50);
  }

  @delay-base: 100;
  #g { .animation-delay(0) }
  #n { .animation-delay(@delay-base * 1) }
  #o { .animation-delay(@delay-base * 2) }
  #w { .animation-delay(@delay-base * 3) }
  #b { .animation-delay(@delay-base * 4) }
  #e { .animation-delay(@delay-base * 5) }
}

.page {
  background-color: white;
  margin-top: 1em;
  padding: 2em 3em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

  .back-top-link {
    display: block;
    padding-bottom: 10px;
  }

  .current-session {
    float: right;
    opacity: 0.3;
  }

  &.credits {
    position: relative;

    h2 { margin-bottom: 1em; }

    .slide-down {
      overflow: hidden;
      min-height: 530px;

      div {
        animation: 22s slide-down linear;
        margin-top: 0%;

        p {
          margin-top: 0.5em;
        }
      }
    }

    @keyframes slide-down {
      from { transform: translateY(-100%); }
      to { transform: translateY(0%); }
    }

    .credits-rose {
      position: absolute;
      right: 2em;
      top: 0;
      height: 500px;
    }
  }

  &.wall {
    h4 {
      margin-top: 1em;
    }
  }
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.dropdown-menu {
  max-height: 30em;
  overflow: auto;
  z-index: 10;

  .selected-course {
    font-weight: bold;
    background: #e74944;
    color: white;

    &:hover {
      background: #e74944;
      color: white;
    }

    &:focus {
      background: #e74944;
      color: white;
    }
  }
}

h2 {
  margin-bottom: 0.5em;
}

legend {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: solid 1px #e3e3e3;
  color: #444;
  font-weight: bold;
}

p { padding: 0 0 1em; }

strong { font-weight: bold; }

div[tabindex="-1"]:focus {
  outline: none;
}

.fixed {
  position: fixed;
}

.main-container {
  .main-content {
    min-height: 100%;
    padding-bottom: 5rem;

    &.unsupported {
      padding-top: 0;
    }

    .loader { display: none }

    // to sem premaknil iz &.overlay
    &::after {
      opacity: 0;
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: rgba(255, 255, 255, 0.8);
      content: '';
    }

    // Fix content container when overlay is active
    &.overlay,
    &.reward {
      position: fixed;
      width: 100%;
      //height: 100%;
      overflow: hidden;
    }

    &.overlay {
      .loader {
        display: block;
        z-index: 1050;
        position: fixed;
        overflow: initial;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        color: @color-primary-red;
      }

      &::after {
        display: block;
        opacity: 1;
        .transition(opacity 0.5s);
      }
    }

    &.liked {
      &::before {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //z-index: 1040;
        background-color: fade(@color-primary-red, 25%);
        opacity: 0; // Hidden by default
        content: '';
        .animation(blink 1.2s);
      }
    }

    .reward-content { display: none }

    &.reward {
      .reward-container {
        margin-top: -4em;

        &.fade-out {
          animation: fade-out 0.5s;
        }

        width: 100%;
        height: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.6);
      }

      .animation(text-glow 3s);
      .animation-delay(500);

      .reward-content {
        display: flex;
        z-index: 1050;
        position: fixed;
        overflow: initial;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        color: white;
        align-items: center;
        justify-content: center;
        text-align: center;
        .animation(zoom-out 0.5s);

        .reward-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 100px 50px;
          height: 100%;
          // margin: auto;
          justify-content: center;
        }

        .reward-title {
          text-transform: uppercase;
          letter-spacing: 2px;
          .font-m1();
          .type-secondary();

          &.editable {
            display: block;
            margin: 0 auto;
            width: 50%;
            text-align: center;
            margin-bottom: 1rem;
            background: rgba(255, 255, 255, 0.2);
            color: white;
          }
        }

        .reward-text {
          color: white;
          line-height: 1.5em;

          &.editable {
            overflow: auto auto;
            font-family: 'NotoSerif', 'CaslonTwoTwentyFour-Bold', Georgia, Times, 'Times New Roman', serif;
            font-size: 2rem;
            display: block;
            margin: 0 auto;
            width: 50%;
            text-align: center;
            background: rgba(255, 255, 255, 0.2);
          }
        }

        .btn {
          margin-top: 50px;
          display: inline-block;
          height: 50px;
          width: 50px;
          overflow: hidden;
          font-size: 20px;
          border: solid 1px white;
          color: white;
          background-color: rgba(255, 255, 255, 0);
          opacity: 0;
          outline: none;

          border-width: 1px;
          .border-radius(50%);
          .animation(rotate-in 1s forwards);
          .animation-delay(0.25);

          &.rating {
            border: solid 1px @color-personal-blu;
            color: @color-personal-blu;

            &:hover {
              background: @color-personal-blu;
              color: white;
            }
          }

          &::after {
            content: "\e96a";
            margin-left: 3px;
          }

          .transition(background-color 0.5s, color 0.5s);

          &:hover {
            background-color: rgba(255, 255, 255, 1);
            color: black;
          }

          &:active,
          &:focus {
            outline: none;
          }

          &.editing {
            &::before {
              content: "\e958";
              margin-left: 1px;
            }

            &::after {
              content: "";
            }
          }
        }

        .rsr.rating-gly-star {
          input {
            display: none;
          }
        }
      }

      .reward-background {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
        content: '';
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        background-color: @color-dark-grey;
        .animation(fade-in 0.5s);

        &::after {
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1040;
          background-color: rgba(0, 0, 0, 0.8);
          content: '';
          .animation(fade-in 0.5s);
        }
      }

      .assessment-background {
        background: white;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
      }

      .assessment-text {
        color: @color-brown;
      }

      #starRating-div {
        .icon {
          font-size: 3rem;
          color: @color-team-yellow;
          cursor: pointer;

          &.hover {
            color: #e3e3e3;
          }
        }
      }

      #reward-emoticon {
        margin-top: 30px;
        width: 74px;
        height: 74px;
        display: none; // Hide it if no emotion is selected

        &.happy {
          display: inline-block;
          g { .animation(fade-in 1s); }
          .sad { display: none }
        }

        &.sad {
          display: inline-block;
          g { .animation(fade-in 1s); }
          .happy { display: none }
        }

        .st0 {
          fill: none;
          stroke: #fff;
          stroke-width: 1.2;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
        }
      }
    }
  }

  .joyride-subtext {
    margin: 1em 0 0;

    &.no-padding {
      padding: 0;
    }
  }

  .joyride-important {
    color: @color-primary-red;
    font-weight: bold;
  }

  .update-notification {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1em 1em 0.8em;
    text-align: center;
    background: @color-audio;
    color: white;
    z-index: 10;
    display: none;

    &.visible {
      display: block;
    }

    a {
      color: white;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.session-block,
.action-item,
.page,
.action { .animation(fade-in 0.7s); }

.action-item.hide-it { .animation(fade-out 0.7s); }

.action-item.disabled { .animation(none); }

// Action colors
.color-qa { color: @color-qa }
.color-text { color: @color-text }
.color-photo { color: @color-photo }
.color-multiple_choice { color: @color-multiple_choice }
.color-assessment { color: @color-assessment }
.color-rating { color: @color-rating }
.color-link { color: @color-link }
.color-external { color: @color-external }
.color-image { color: @color-image }
.color-video { color: @color-video }
.color-audio { color: @color-audio }
.color-upload { color: @color-upload }
.color-certificate { color: @color-certificate }
.color-discussion { color: @color-discussion }
.color-notes { color: @color-note }
.color-team-yellow { color: @color-team-yellow }
.color-word_puzzle { color: @color-word_puzzle }

// Classes that contains icomoon font
.back,
.checkmark,
.clock::after,
.btn.share::before,
.btn.correct::after,
.btn.wrong::after,
.journey header h2.group::after,
.quote-text::after,
.quote-text::before,
.reward-content .btn {
  /* use !important to prevent issues with browser extensions that change fonts */
  outline: none;
  font-family: 'icomoon', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:active,
  &:focus {
    outline: none;
  }
}

// Global Action modal styles
.action-modal {
  ul,
  ol {
    margin: 0 0 0 2em;
  }

  ul { list-style: initial; }

  ol { list-style: decimal; }

  textarea {
    width: 100%;
    height: 200px;
    border: 1px solid @color-brown-light;
    //margin-bottom: 30px;
    padding: 15px;
    .border-radius(7px);
  }

  .action-photo {
    margin: 0 auto 2em;
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    cursor: zoom-in;
    .transition(0.3s ease);

    &.large {
      cursor: zoom-out;
      width: 100%;
      height: 400px;
    }
  }

  // Action modal buttons
  .action-modal-buttons {
    margin-top: 40px;
    position: relative;
    //text-align: center;

    /*
    display: flex;
    justify-content: space-between;
    align-items: center;

    .element-container {
      width: 33%;
      text-align: center;
      &:first-child { text-align: left }
      &:last-child { text-align: right }
    }
    */

    // Chekmark button
    .checkmark {
      outline: none;
      margin: 0 auto;
      display: block;
      height: 50px;
      width: 50px;
      overflow: hidden;
      font-size: 20px;
      position: relative; // absolute position fix
      .border-radius(50%);

      &:active,
      &:focus {
        outline: none;
      }

      // Translate transform FIX & other
      &::after,
      &::before {
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 5px;
        .transition(transform 0.3s ease-in);
      }

      &::after {
        top: 15px;
        left: 15px
      } // Center checkmark

      // Hide next icon & other
      &::before {
        content: "\e96a";
        top: 15px;
        left: 17px; // Center next
        .translateX(-40px);
      }

      &.disabled {
        border: solid 1px @color-brown-light;
        color: @color-brown-light;

        &::after { content: "\e958"; }
      }

      &.enabled {
        background-color: @color-brown-light;
        border: none;
        color: white;
        .transition(background-color 0.5s);

        &::after { content: "\e958"; }

        .action-qa & {
          &.done,
          &:hover {
            background-color: @color-qa;
          }
        }

        .action-text & {
          &.done,
          &:hover {
            background-color: @color-text;
          }
        }

        .action-photo & {
          &.done,
          &:hover {
            background-color: @color-photo;
          }
        }

        .action-multiple_choice & {
          &.done,
          &:hover {
            background-color: @color-multiple_choice;
          }
        }

        .action-rating & {
          &.done,
          &:hover {
            background-color: @color-rating;
          }
        }

        .action-link & {
          &.done,
          &:hover {
            background-color: @color-link;
          }
        }

        .action-external & {
          &.done,
          &:hover {
            background-color: @color-external;
          }
        }

        .action-image & {
          &.done,
          &:hover {
            background-color: @color-image;
          }
        }

        .action-video & {
          &.done,
          &:hover {
            background-color: @color-video;
          }
        }

        .action-audio & {
          &.done,
          &:hover {
            background-color: @color-audio;
          }
        }

        .action-upload & {
          &.done,
          &:hover {
            background-color: @color-upload;
          }
        }

        .action-certificate & {
          &.done,
          &:hover {
            background-color: @color-certificate;
          }
        }

        .action-discussion & {
          &.done,
          &:hover {
            background-color: @color-discussion;
          }
        }

        .action-notes & {
          &.done,
          &:hover {
            background-color: @color-note;
          }
        }

        .action-word_puzzle & {
          &.done,
          &:hover {
            background-color: @color-qa;
          }
        }

        &.done {
          &::after {
            .translateX(40);
          }

          &::before {
            .translateX(0);
          }
        }
      }
    }

    .remind-me {
      position: absolute;
      left: 0;

      .selector {
        display: none;
        padding-top: 10px;

        .btn {
          border-radius: 50%;
          height: 30px;
          width: 30px;
          overflow: hidden;
          padding: 0;
          margin-left: 5px;
        }

        .form-group {
          margin: 0;
          display: inline;
          &:last-of-type { margin-right: 5px }
        }
      }

      .clock {
        color: @color-brown-light;
        border: none;
        .transition(color, 0.5s);

        &::after {
          text-align: center;
          content: "\e963";
          display: block;
          font-size: 20px;
        }

        &:hover,
        &:active,
        &:focus {
          background: none;
          color: @color-primary-red;
        }

        &.reminder-active {
          color: lighten(@color-primary-red, 5%);
          &:hover { color: @color-primary-red; }
        }
      }

      /*
      In case we cannot change reminder:
      &.active .clock { color: @color-primary-red; cursor: @cursor-disabled; }
      */
      &.open {
        .selector { display: block }
        .clock { display: none }
      }

      &.invisible {
        button,
        .selector { display: none }
      }
    }

    .share-button {
      display: none;

      &.visible {
        display: block;
      }
    }

    .share-status {
      font-size: 0.9em;
      margin-bottom: 0.5rem;
      color: @color-team-yellow;
    }

    .btn.share {
      position: absolute;
      right: 0;
      color: @color-team-yellow;
      border-color: @color-team-yellow;
      border-width: 1px;
      text-align: center;
      background: none;
      height: 50px;
      width: 50px;
      border-radius: 25px;
      padding-left: 16px;
      .transition (color 0.3s, background-color 0.3s);

      &::before {
        content: "\e903";
        font-size: 17px;
      } // Team icon

      &:hover { background-color: lighten(@color-team-yellow, 35%) }

      &.shared {
        background-color: @color-team-yellow;
        color: white;

        &:hover {
          background-color: darken(@color-team-yellow, 10%);
        }
      }
    }
  }

  .action-modal-activity {
    margin: 30px 0;

    &.qa {
      .qa-photo {
        margin: 0 auto 2em;
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        cursor: zoom-in;

        &.large {
          cursor: zoom-out;
          width: 100%;
          height: 400px;
        }
      }
    }

    &.photo {
      form {
        margin-bottom: 20px;
        input { margin-bottom: 10px }
      }
    }

    &.rating {
      text-align: center;
    }

    &.multiple_choice {
      text-align: center;
      margin-top: 30px;

      .btn {
        width: 80%;
        margin-bottom: 15px;
        padding: 15px 20px;
        border-color: @color-multiple_choice;
        border-width: 1px;
        color: @color-multiple_choice;
        background-color: transparent;
        font-weight: bold;
        border-radius: 3px;
        position: relative; // Icons absolute positioning fix
        white-space: normal;
        .transition(color 0.3s, background-color 0.3s);

        &:hover {
          background-color: lighten(@color-multiple_choice, 65%);
          //color: white;
        }

        &.selected {
          background-color: @color-multiple_choice;
          color: white;
        }

        &.selected:hover { background-color: lighten(@color-multiple_choice, 10%); }

        &.correct,
        &.correct:hover {
          background-color: @color-correct;
          color: white;
          border-color: @color-correct;
          span { visibility: hidden }

          &::after {
            content: "\e958";
            position: absolute;
            top: 13px;
            left: 48%;
            font-size: 22px;
          }
        }

        &.wrong,
        &.wrong:hover {
          background-color: @color-wrong;
          color: white;
          border-color: @color-wrong;
          span { visibility: hidden }

          &::after {
            content: "\e91b";
            position: absolute;
            top: 13px;
            left: 48%;
            font-size: 22px;
          }
        }
      }
    }
  }
}

.journey {
  .hidden {
    display: none;
  }

  // .page { min-height: 700px; }
  header {
    margin-bottom: 30px;

    h2.group {
      margin-bottom: 5px;
      // &::after {
      //   content: "\e917";
      //   margin-left: 10px;
      //   opacity: 0.3;
      //   font-size: 1rem;
      // }

      .member-list-button {
        float: right;
        font-family: 'NotoSans', 'NeueHaasUnicaPro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 12;
        // padding: 7px 10px;
      }
    }

    .course {
      color: @color-team-yellow;
    }
  }

  .opened-form {
    width: 100%;
    height: 80px;
    border-width: 1;
    border-color: #c7c7c7;
    padding: 7px 10px;
    margin-right: 10;
    // transition: 0.3s;
    border-radius: 3;
  }

  .closed-form {
    width: 100%;
    height: 34px;
    border-width: 1;
    border-color: #c7c7c7;
    padding: 7px 10px;
    margin-right: 10;
    // transition: 0.3s;
    border-radius: 3;
  }

  .journey-grid {
    @apply grid gap-4 grid-cols-2 md:grid-cols-3;
  }

  &.journey-single {
    .journey-grid {
      @apply grid-cols-2 md:grid-cols-3;
    }
  }

  .journey-item-v2 {
    // margin-bottom: 20px;
    background: white;
    cursor: pointer;
    // border-radius: 3px;
    // display: flex;

    .journey-item-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      .content {
        flex: 1;
      }
    }

    .source {
      text-align: center;
      margin: 15px;
      color: @color-brown;
      font-size: 0.9rem;
      line-height: 1.2rem;
    }

    .header {
      padding-bottom: 15px;
      overflow: auto;

      .profile-img {
        width: 50px;
        height: 50px;
        float: left;
      }

      .meta {
        margin-left: 65px;

        .name {
          color: @color-well;
          .font-m1();
        }

        .time {
          float: right;
          opacity: 0.5;
          .font-s2();
        }

        .title {
          //clear: both;
          font-weight: bold;
          color: @color-full;
          display: block;
        }
      }
    }

    .footer {
      border-top: solid 1px @color-brown-lighter;
      overflow: auto;
      padding: 10px 15px;
      display: flex;
      align-items: center;

      .profile-img {
        width: 40px;
        height: 40px;
        float: left;
      }

      .meta {
        text-align: center;
        flex: 1;
        margin-left: 10px;
        margin-top: 3px;

        .author {
          word-break: break-word;
        }

        .name {
          color: @color-well;
          display: block;
        }

        .time {
          opacity: 0.5;
          font-size: 0.8rem;
          .font-s2();
        }

        .title {
          //clear: both;
          font-weight: bold;
          color: @color-full;
          display: block;
        }
      }
    }

    .content {
      clear: both;
      overflow: hidden;
    }

    .share-like {
      overflow: auto;
      margin: 15px 15px 11px;

      .delete-share {
        display: inline-block;
        margin-left: 10px;
        font-size: 0.9rem;
      }
    }

    // Comments counter
    .comments-counter {
      color: @color-brown;
      .font-s3(); // Predefined font size
      .counter { margin-left: 4px /* rtl: ignore */; }
      // .scaleX(-1);

      &.red { color: @color-primary-red; }

      &:hover {
        color: @color-primary-red;
        text-decoration: none;
      }
    }

    // Likes counter
    .likes-counter {
      color: @color-brown;
      display: inline;
      .font-s3(); // Predefined font size

      a {
        color: @color-brown;
        &:hover { color: @color-primary-red; }
      }

      &.red {
        color: lighten(@color-primary-red, 5%);
        &:hover { color: @color-primary-red; }
      }

      &:hover {
        color: @color-primary-red;
        text-decoration: none;
      }
    }
  }

  .other-shares {
    margin-top: 1.5rem;

    .separator {
      color: white;
      text-transform: uppercase;
      text-align: center;
      margin: 0 0 1.5rem;
      font-size: 1rem;
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .wall-item-details {
    right: -625px;
    top: 50%;
    margin-bottom: 1em;
    position: absolute;
    width: 555px;
    background-color: white;
    //margin-top: 1em;
    padding: 2em 2em;
    cursor: default;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

    .animation(chat-in 0.5s);

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid white;
      top: 30px;
      right: 100%;
    }

    h3 {
      margin-bottom: 0.5em;
      position: relative;

      .close {
        position: absolute;
        top: -7px;
        right: 0;
        color: @color-dark-grey;
        background: none;
        border: none;
        padding: 0;
        font-weight: normal;
      }
    }

    &.hide-it { .animation(fade-out 0.5s); }

    @media (max-width: 991px) {
      position: static;
      right: 0;
      top: 0;
      width: 100%;
      box-shadow: none;
      padding: 0;
      padding-top: 20px;

      h3,
      &::after,
      .no-messages { display: none }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      width: 455px;
      right: -525px;
    }
  }

  .item-details {
    margin-top: 1.5rem;
    background: white;
    padding: 20px 15px;
    text-align: left;
    border-radius: 3px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    .context-bar {
      display: flex;
      border-bottom: solid 1px #e1dcd7;
      margin: -15px -15px 20px;
      align-items: center;

      .back-button {
        width: 75px;
        padding: 10px 15px 15px;
        cursor: pointer;

        img {
          transform: rotate(0deg) /* rtl: rotate(180deg) */;
        }
      }

      .context {
        flex: 1;
        text-align: center;

        .small {
          color: @color-team-yellow;
          display: block;
        }
      }

      .nothing {
        width: 75px;
      }
    }

    .action {
      .title {
        font-weight: bold;
        margin-bottom: 15px;
      }
    }

    .separator {
      border-top: solid 1px @color-personal-blu;
      margin: 20px 0;
    }

    .answer {
      word-wrap: break-word;

      .author {
        display: flex;
        align-items: center;
        cursor: pointer;

        .profile-img {
          width: 30px;
          height: 30px;
          float: left;
        }

        .name {
          flex: 1;
          margin-left: 10px;
          word-break: break-all;

          .time {
            float: right;
            color: #e1dcd7;
            font-size: 0.8rem;
          }
        }
      }

      .text {
        margin: 15px 0;
        line-height: 1.5rem;
      }

      .photo {
        margin: 15px -15px;
      }
    }

    .reactions {
      overflow: auto;

      .likes-counter {
        color: @color-brown;
        display: inline;
        .font-s3(); // Predefined font size

        a {
          color: @color-brown;
          &:hover { color: @color-primary-red; }
        }

        &.red a {
          color: lighten(@color-primary-red, 5%);
          &:hover { color: @color-primary-red; }
        }

        &:hover {
          color: @color-primary-red;
          text-decoration: none;
        }
      }

      // Comments counter
      .comments-counter {
        color: @color-brown;
        .font-s3(); // Predefined font size

        .counter { margin-left: 4px /* rtl: ignore */; }

        &.red { color: @color-primary-red; }

        &:hover {
          color: @color-primary-red;
          text-decoration: none;
        }
      }
    }
  }

  .gnowbe-rose-container {
    text-align: center;
    padding-top: 1em;

    p {
      color: white;
      padding-left: 30px;
      margin-top: 20px;
      opacity: 0.5;
    }

    .rose-text-container {
      position: fixed;
      top: ~"calc(50% - 230px)";
      left: 65%;
      text-align: center;
    }

    .gnowbe-rose {
      width: 240px;
      height: 440px;
      //margin-top: 100px;

      .st0 {
        opacity: 0.3;
        fill: none;
        stroke: #fff;
        stroke-miterlimit: 10;
        .animation(steam-fade linear 1s);
      }

      .st1 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #fff;
        opacity: 0;
        .animation(pop-in 0.5s linear forwards);
        .transform-origin(50 50);
      }

      #leaf-1 { .animation-delay(0) }
      #leaf-2 { .animation-delay(50) }
      #leaf-3 { .animation-delay(100) }
      #leaf-4 { .animation-delay(150) }
      #leaf-5 { .animation-delay(200) }
      #leaf-6 { .animation-delay(250) }
      #leaf-7 { .animation-delay(300) }
      #leaf-8 { .animation-delay(350) }
      #leaf-9 { .animation-delay(400) }
      #leaf-10 { .animation-delay(450) }
      #leaf-11 { .animation-delay(500) }
      #leaf-12 { .animation-delay(550) }
    }
  }
}

.chat {

  // Force scrollbar to show in webkit
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: fade(@color-brown, 80%);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .messages {
    max-height: 400px;
    overflow-y: scroll;
  }

  .item-meta {
    .profile-img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      float: left;
    }

    .moment {
      //float: right;
      opacity: 0.5;
      margin-left: 1em;
      .font-s2();
    }

    .trash-div {
      float: right;
    }
  }

  .no-messages {
    font-style: italic;
    opacity: 0.5;
  }

  .message {
    margin-top: 5px;
    margin-left: 35px;
    clear: both;
  }

  input {
    border: 1px solid @color-brown-light;
    //margin-bottom: 30px;
    padding: 5px;
    width: 100%;
    margin-top: 1em;
    .border-radius(3px);
  }
}

.page.activity {
  h4 {
    color: @color-brown-light;
    margin-top: 20px;
    padding-bottom: 5px;
    .type-regular();
  }

  ul li {
    border-top: 1px solid @color-brown-lighter;
    color: @color-medium;
    overflow: auto;
    cursor: pointer;

    & > div { display: inline }

    .profile-img {
      width: 30px;
      height: 30px;
      float: left;
    }

    &.active {
      background-color: lighten(@color-brown, 65%);
      color: @color-well;
    }
  }

  .profile-img {
    width: 30px;
    height: 30px;
    float: left;
  }
}

// Profile page
.page.profile {
  .profile-header {
    margin-bottom: 30px;
  }

  .profile-name {
    text-align: center;
  }

  .profile-photo-container .profile-img,
  .profile-name .profile-img {
    width: 100%;
    padding-top: 100%;
    display: inline-block;
    border: 1px solid @color-brown-lighter;
  }

  .profile-stats > div {
    text-align: center;
    margin: 20px 0;

    .number {
      font-weight: bold;
      color: @color-well;
    }

    .name {
      margin-top: 5px;
      text-transform: uppercase;
      color: @color-brown;
    }
  }

  .profile-buttons {
    .btn { width: 100% }
  }

  .btn {
    .icon {
      font-size: 17px;
      margin-right: 5px;
      margin-top: -3px;
      top: 2px;
      position: relative;
    }
  }

  ul.project-info {
    li {
      list-style: none;
      display: inline;
      padding: 0;

      &::after {
        content: "\00b7";
        font-size: 18px;
        margin: 0 5px 0 3px;
      }

      &:last-child::after { content: none; }
    }
  }

  .completed-course-item {
    text-align: center;
    min-height: 370px;
    cursor: pointer;

    // Responsive sqare image
    .course-cover {
      float: left;
      position: relative;
      width: 100%;
      padding-bottom: 100%; /* = width for a 1:1 aspect ratio */
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: @color-brown-lighter;
      margin-bottom: 10px;
      border-radius: 4px;
    }

    .course-completed {
      clear: both; // cover image position fix
      border: 1px solid @color-multiple_choice;
      border-radius: 5px;
      padding: 5px;
      color: @color-multiple_choice;
      margin-bottom: 10px;
    }

    .course-title { font-weight: bold }
    .course-subtitle { color: @color-medium; }
  }

  ul.notification-settings {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      border-top: 1px dashed @color-rare;
      color: @color-medium;
      overflow: auto;

      &:last-child { border-bottom: 1px dashed @color-rare; }

      & > div { display: inline }

      .course-img {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        //margin-right: 10px;
        background-color: @color-brown-lighter;
        display: inline-block;
        // float: left;
        margin-right: 15px;
      }

      .title {
        flex: 1;
        vertical-align: text-bottom;

        &.no-margin {
          margin-left: 0;
        }

        &.margin-top {
          margin-top: 8px;
        }

        > span {
          display: block;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          width: 100%;

          &.company-name {
            font-size: 0.85em;
            color: @color-medium-rare;
          }
        }
      }
    }

    &.more-padding {
      li {
        padding-top: 10px;
        align-items: flex-start;

        .title {
          margin-right: 15px;
        }
      }
    }
  }
}

// Custom Trix CSS
.trix_editor_wrapper {
  display: flex;
  flex-direction: column;
}

.trix_editor_wrapper trix-toolbar {
  flex: 0;
}

.trix_editor_wrapper trix-editor {
  flex: 1;
  overflow-y: scroll;
}

// Force scroll bar to be shown in OSX
.trix_editor_wrapper trix-editor::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.trix_editor_wrapper trix-editor::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.trix_editor_wrapper:not(.dont-hide-toolbar) trix-toolbar {
  display: none;
}

.trix_editor_wrapper.focused trix-toolbar {
  display: block;
}

.trix_editor_wrapper:not(.enable-headings) trix-toolbar .button_groups .button_group.block_tools .heading-1,
.trix_editor_wrapper:not(.enable-headings) trix-toolbar .button_groups .button_group.block_tools .heading-2,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.block_tools .nesting-level,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.text_tools .italic,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.text_tools .strike,
.trix_editor_wrapper:not(.enable-links) trix-toolbar .button_groups .button_group.text_tools .link,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.block_tools .quote,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.block_tools .code,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.block_tools .block-level {
  display: none;
}

trix-editor h1,
trix-editor h2,
.trix h1,
.trix h2 {
  line-height: 3rem;
}

trix-editor div,
.trix div,
trix-editor li,
.trix li {
  line-height: 1.8rem;
}

trix-editor strong,
.trix strong {
  font-weight: 900;
}

trix-editor ul,
.trix ul {
  list-style-type: disc;
  padding-left: 30px;
}

trix-editor ol,
.trix ol {
  list-style-type: decimal;
  padding-left: 30px;
}

// other

.notification {
  position: fixed;
  right: 1em;
  top: 5em;
  padding: 1rem 1.2rem 0.83rem;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 3px;
}

/* rtl:begin:ignore */
#help {
  .joyride-beacon {
    position: fixed;
    bottom: 1rem;
    left: 1.1rem;
    opacity: 0.4;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;

      .joyride-beacon__inner {
        background-color: @color-multiple_choice;
      }

      .joyride-beacon__outer {
        background-color: rgba(27, 88, 94, 0.2);
        border: 2px solid @color-multiple_choice;
      }
    }
  }

  .joyrides-container {
    display: flex;
    flex-direction: column;
    background: white;
    width: 400px;
    bottom: 0;
    margin-bottom: 0;
    left: 30px;
    position: fixed;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.16);
    z-index: 100;
    opacity: 0;
    // transition: opacity 0.2s, max-height 0s 0.2s, margin-bottom 0s 0.2s;
    max-height: 0;

    &.visible {
      opacity: 1;
      max-height: 500px;
      margin-bottom: 130px;
      // transition: opacity 0.2s, max-height 0s, margin-bottom 0s;
    }

    .header {
      font-size: 1.1rem;
      background: @color-personal-blu;
      padding: 25px 20px 21px;
      color: white;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);

      .close-button {
        margin-top: -19px;
        font-weight: bold;
        color: #c4e0e2;
        font-size: 1.5rem;
        text-shadow: none;
        padding: 5px 11px 3px;
        border-radius: 5px;
        // transition: 0.2s;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
      }
    }

    .body {
      padding: 20px;
      min-height: 250px;

      .title {
        font-weight: bold;
        margin-bottom: 15px;

        &.second {
          margin-top: 25px;
        }
      }

      p {
        margin-bottom: 15px;
        padding-bottom: 0;
        line-height: 25px;
      }

      a {
        color: @color-wrong;
      }

      ul {
        list-style-type: none;
        line-height: 28px;

        &.nomargin {
          margin-bottom: 0;
        }

        li {
          display: flex;
          align-items: center;

          &::before {
            font-family: 'icomoon', sans-serif;
            content: "\e936";
            margin-top: -2px;
          }

          &.seen {
            &::before {
              content: "\e933";
              color: #28a528;
              margin-top: -2px;
            }
          }

          div {
            display: inline-block;
            margin-left: 10px;
          }

          .seen {
            color: @color-medium;
            text-decoration: line-through;
          }

          .notAvailable {
            color: #adadad;
            cursor: not-allowed;
          }
        }
      }
    }

    .footer {
      padding: 20px 20px 18px;
      border-top: solid 1px #e5e5e5;
      background: #f7f7f7;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 0.9rem;
      text-align: right;
      color: #949494;
    }
  }

  .bubble {
    position: fixed;
    display: inline-block;
    background: @color-multiple_choice;
    bottom: -50px;
    left: 1rem;
    border-radius: 27px;
    padding: 1em 2em 0.9em;
    color: @color-light-green;
    font-size: 0.9rem;

    &.active {
      bottom: 8.5rem;
    }
  }

  .clippy {
    width: 100px;
    position: fixed;
    bottom: 0;
    left: 1.1rem;
    transform: translateY(2rem);
    transition: transform 0.5s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: translateY(-1rem);
    }

    &.active {
      bottom: 1rem;
      transform: none;
    }
  }
}

/* rtl:end:ignore */

// #help {
//   .joyrides-container {
//     .header {
//       .close-button {
//         float: right /* rtl: left */;
//       }
//     }
//   }
// }

.tooltip.custom {
  white-space: nowrap;
  opacity: 1;

  a {
    color: @color-team-yellow;
  }

  &.right {
    .tooltip-arrow {
      border-right-color: @color-multiple_choice;
    }
  }

  &.left {
    .tooltip-arrow {
      border-left-color: @color-multiple_choice;
    }
  }

  &.top {
    .tooltip-arrow {
      border-top-color: @color-multiple_choice;
    }
  }

  &.bottom {
    .tooltip-arrow {
      border-bottom-color: @color-multiple_choice;
    }

    &.bleft {
      .tooltip-arrow {
        left: 2.5em;
      }
    }

    &.bright {
      .tooltip-arrow {
        right: 2.5em;
        left: auto;
      }
    }
  }

  &.skratekballoon {
    .tooltip-arrow {
      left: 31% !important;
    }
  }

  .tooltip-inner {
    max-width: none;
    padding: 0.5rem 0.75rem;
    color: white;
    text-align: center;
    background-color: @color-dark-grey;
    border-radius: 4px;
    font-size: 0.9rem;
  }

  &.daily-learning {
    white-space: normal;

    .tooltip-inner {
      max-width: 350px;
      text-align: left;
    }
  }

  &.pushnot,
  &.emailmsg {
    white-space: normal;

    .tooltip-inner {
      max-width: 250px;
      text-align: left;
    }
  }
}

.action-contenttype-block {
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  position: relative;
  transition: background-color 0.2s;

  &:hover {
    background: #fdfdfd;
  }

  &.selected {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  }

  &.disabled {
    border: 1px solid #dadada !important;
    background-color: #f7f7f7 !important;
    color: #c3c3c3 !important;
    cursor: not-allowed;
  }

  .action-contenttype-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.custom-modal-design,
.add-action-modal {
  @apply pr-0;

  .modal-dialog {
    margin: 5% auto 0;

    .modal-content {
      @apply rounded-md shadow-md border border-lightgray h-full flex;

      .modal-header {
        @apply p-8 pb-2 rounded-t-md border-none items-start;

        .close {
          margin-top: -5px;
          color: #ababab;
          text-shadow: none;

          &:hover {
            color: #b1b1b1;
          }
        }

        .modal-title {
          @apply m-0 font-bold text-deepgray;
        }
      }

      .modal-body {
        max-height: ~"calc(100vh - 250px)";
        overflow-y: auto;

        @apply flex flex-col flex-1 max-h-full px-8;

        &.no-height {
          max-height: none;
        }

        ul,
        ol {
          margin: 0 0 0 2em;
        }
        ul { list-style: initial; }
        ol { list-style: decimal; }
      }

      .modal-footer {
        @apply p-8 pt-2 rounded-b-md border-none;
      }
    }
  }

  .action-hint {
    float: left;
    font-size: 13px;
    max-width: 430px;
    margin-top: 2px;
    text-align: left;
  }
}

.custom-modal-design {
  .modal-dialog {
    max-width: 650px;
  }

  &.create-new-program-modal {
    .btn-primary {
      background-color: @color-team-yellow;
      border-color: @color-team-yellow;

      &:hover,
      &:focus {
        background-color: darken(@color-team-yellow, 12%) !important;
        border-color: darken(@color-team-yellow, 12%) !important;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem lighten(@color-team-yellow, 25%) !important;
      }

      &:active {
        background-color: darken(@color-team-yellow, 15%) !important;
        border-color: darken(@color-team-yellow, 15%) !important;

        &:focus {
          box-shadow: 0 0 0 0.2rem lighten(@color-team-yellow, 20%) !important;
        }
      }
    }
  }
}

.add-action-modal {
  .modal-dialog {
    @apply m-0 h-full w-full max-w-full !important;

    .action-contenttype-block.assessment,
    .action-contenttype-block.certificate,
    .action-contenttype-block.paste {
      margin-bottom: 0;
    }

    .modal-content {
      .modal-body {
        padding-bottom: 0;
      }
    }
  }
}

@screen md {
  .add-action-modal {
    .modal-dialog {
      width: 860px !important;
      margin: 5% auto !important;

      @apply h-auto max-w-none !important;

      .modal-content {
        .modal-body {
          max-height: calc(100vh - 250px) !important;
        }
      }
    }
  }
}

.custom-modal-design.post {
  display: flex;
  flex-direction: column;
  padding: 0 !important;

  .modal-dialog {
    max-width: 900px;
    width: 100%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;

    @apply m-0 h-full;

    @screen md {
      .custom-modal-design.post {
        .modal-dialog {
          margin: 50px auto !important;
          height: 550px;
          max-height: 550px;
          width: 860px !important;
        }
      }
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      height: 100%;

      .modal-body {
        overflow: hidden;
        max-height: 550px;
        padding: 0;
        min-height: 100%;
        height: 100%;

        .post-details {
          display: flex;
          flex-direction: column;
          max-height: 550px;
          min-height: 100%;
          height: 100%;
        }

        .answer {
          overflow: auto;
          margin-right: 330px;
          background: @color-raw;

          .photo {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            // padding-bottom: 100%;
          }

          .text {
            padding: 15px;
            line-height: 1.5rem;
            word-break: break-word;
          }
        }

        .meta {
          padding: 15px 15px 12px;
          right: 0;
          font-size: 14px;
          overflow: hidden;
          word-break: break-word;
          display: flex;
          flex-direction: column;

          @apply w-full;

          @screen md {
            .custom-modal-design.post {
              .modal-dialog {
                .modal-content {
                  .modal-body {
                    .meta {
                      @apply w-80;
                    }
                  }
                }
              }
            }
          }

          .action {
            margin-bottom: 10px;
          }

          .chat-messages {
            line-height: 1.3rem;

            &.simple {
              .simple-message {
                margin-bottom: 5px;

                .timestamp {
                  display: inline-block;
                  margin-right: 10px;
                  color: silver;
                  font-size: 0.8rem;
                }

                .icon-trash {
                  font-size: 12px;
                  margin-top: 2px;
                  color: silver;
                  opacity: 0;
                  transition: 0.3s;
                  cursor: pointer;
                }
              }

              &:hover {
                .icon-trash {
                  opacity: 1;

                  &:hover {
                    color: @color-primary-red;
                  }
                }
              }
            }
          }

          .share-like {
            display: flex;

            .messageInputForm {
              flex: 1;

              .peer-suggestions {
                bottom: 36px !important;
                right: -70px !important;
              }

              .messageInput {
                width: 100%;
                padding: 0.25rem 0;
                border: none;
              }

              .messageInput__input {
                border: none;
                font: normal 14px Arial, sans-serif;
                background: transparent;
                resize: none !important;
                padding: 0;
                width: 100%;
                flex: 1;

                &::placeholder {
                  color: @color-rare;
                }
              }
            }

            .comments-counter {
              color: @color-medium-well;

              &.red { color: @color-primary-red; }

              &:hover {
                color: @color-primary-red;
                text-decoration: none;
              }
            }

            .likes-counter {
              color: @color-medium-well;

              &.red { color: @color-primary-red; }

              &:hover {
                color: @color-primary-red;
                text-decoration: none;
              }
            }

            .icon-comment-light,
            .icon-heart-light,
            .icon-comment-solid,
            .icon-heart-solid {
              margin-top: 3px;
              float: left;
            }
          }
        }
      }
    }
  }
}

////////// asdadsasda

.single-post {
  background: white;
  display: flex;
  flex-direction: column;
  min-height: 550px;
  height: 550px;

  .post-details {
    display: flex;
    flex-direction: column;
  }

  .answer {
    overflow: auto;
    margin-right: 315px;
    background: @color-raw;

    .photo {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      // padding-bottom: 100%;
    }

    .text {
      padding: 15px;
      line-height: 1.5rem;
      min-height: 100%;
      word-break: break-word;
    }
  }

  .meta {
    padding: 15px 15px 12px;
    font-size: 14px;
    overflow: auto;
    word-break: break-word;
    display: flex;
    flex-direction: column;

    .action {
      margin-bottom: 10px;
    }

    .chat-messages {
      line-height: 1.3rem;

      &.simple {
        .simple-message {
          margin-bottom: 5px;

          .timestamp {
            display: inline-block;
            margin-right: 10px;
            color: silver;
            font-size: 0.8rem;
          }

          .icon-trash {
            font-size: 12px;
            margin-top: 2px;
            color: silver;
            opacity: 0;
            transition: 0.3s;
            cursor: pointer;
          }
        }

        &:hover {
          .icon-trash {
            opacity: 1;

            &:hover {
              color: @color-primary-red;
            }
          }
        }
      }
    }

    .share-like {
      display: flex;
      flex-direction: column;

      .messageInputForm {
        flex: 1;

        .peer-suggestions {
          bottom: 36px !important;
          right: -70px !important;
        }

        .messageInput {
          width: 100%;
        }

        .messageInput__input {
          border: none;
          font: normal 14px Arial, sans-serif;
          background: transparent;
          resize: none !important;
          padding: 0;
          width: 100%;
          flex: 1;

          &::placeholder {
            color: @color-rare;
          }
        }
      }

      .comments-counter {
        color: @color-medium-well;
        &.red { color: @color-primary-red; }

        &:hover {
          color: @color-primary-red;
          text-decoration: none;
        }
      }

      .likes-counter {
        color: @color-medium-well;
        &.red { color: @color-primary-red; }

        &:hover {
          color: @color-primary-red;
          text-decoration: none;
        }
      }

      .icon-comment-light,
      .icon-heart-light,
      .icon-comment-solid,
      .icon-heart-solid {
        margin-top: 3px;
        float: left;
      }
    }
  }
}

.loading {
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  .loader {
    color: white;
  }

  &.active {
    display: flex;

    .loader {
      display: block;
    }
  }
}

// tic tac toe

.ttt {
  background: #fafafa no-repeat center center fixed;
  background-size: cover;

  h1,
  h2 {
    color: @color-dark-grey;
    // text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  }

  &.morning { background-image: url(/app/common/images/bg-blur-morning.jpg) }
  &.lunch { background-image: url(/app/common/images/bg-blur-lunch.jpg) }
  &.afternoon { background-image: url(/app/common/images/bg-blur-afternoon.jpg) }
  &.night { background-image: url(/app/common/images/bg-blur-night.jpg) }

  &.fade-in {
    .animation(fade-in 0.5s);
  }

  &.fade-out {
    .animation(fade-out 0.5s);
  }

  .grid {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cell,
  .cell-p1,
  .cell-p2 {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-right: 10px;
    margin-bottom: 10px;
    opacity: 0.75;
    background-color: #e6e6e6;
    // box-shadow: 2px 2px 0 @color-brown;
    transition: all 0.2s;
    cursor: pointer;
  }

  .cell-p1 {
    background-color: @color-primary-red;
    cursor: default;
  }

  .cell-p2 {
    background-color: @color-personal-blu;
    cursor: default;
  }

  .announcement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25%;
    bottom: 25%;
    left: 20%;
    right: 20%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.8);
    text-align: center;
    font-size: 2em;
    color: white;

    p {
      margin: 0;
      padding: 0;
    }

    button {
      display: none;
      height: 50px;
      width: 100px;
      font-size: 0.5em;
      font-weight: bold;
      border: none;
      border-radius: 10px;
      background-color: white;
      color: purple;
    }
  }
}

// hide help gnowbe when printing

@media print {
  html,
  body {
    width: 100%;
  }

  .main-container {
    .main-content {
      .container {
        padding: 0 !important;
        margin: 0 !important;
        max-width: 100%;

        .offset-lg-2,
        .offset-md-1 {
          margin-left: 1%;
        }

        .col-lg-8,
        .col-md-10,
        .col-sm-12 {
          flex: 0 0 98%;
          max-width: 100%;
        }

        img {
          max-width: 250px;
        }
      }
    }
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  .ibg-notification {
    display: none !important;
  }

  .tooltip {
    display: none !important;
  }
}

#instabugSDK {
  bottom: 55px !important;
  z-index: 9998 !important;
}

// firehelp

.fire-container {
  border-radius: 4px;
  background: #fff9ed;
  color: @color-dark-grey;
  position: relative;
  margin-top: 1rem;
  opacity: 1;
  max-height: 700px;
  transition: opacity 0.2s;
  visibility: visible;

  &.hide-me {
    opacity: 0;
    max-height: 0;
    visibility: hidden;
  }

  ul,
  ol {
    margin: 0 0 0 2em;
  }

  ul { list-style: initial; }
  ol { list-style: decimal; }
}

// ??
.fire-help {
  transition: height 0.2s;
  position: relative;

  .fire-content {
    display: flex;
    background: #fff7e8;
    border: solid 1px #ffdc9e;
    border-radius: 4px;
    align-items: stretch;

    .hint {
      width: 75px;
      background: #ffdc9e;
      font-size: 0.85rem;
      padding-top: 2px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      font-weight: bold;
      color: #ca9435;

      &::before,
      &::after {
        content: "";
        position: absolute;
        z-index: 1000;
        top: -7px;
        left: 50%;
        margin-left: -8px;
        border-top: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #ffdc9e;
      }
    }

    .text {
      flex: 1;
      padding: 10px 15px 7px;
      font-size: 0.85rem;
      line-height: 1.3rem;
      color: #d6a349;
    }
  }

  .edit {
    position: absolute;
    z-index: 999;
    right: 10px;
    bottom: 5px;
  }
}

.image-holder {
  overflow: unset;
  background: #f9f9f9;
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;

  // &.video {
  //   width: auto;
  // }


  &.large {
    width: auto !important;
    height: unset !important;
    margin: 0 -150px;

    .image {
      cursor: zoom-out;
      border-radius: 4px;
    }
  }

  .trash {
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 40px;
    cursor: pointer;
  }

  .image {
    max-height: 300px;
    object-fit: cover;
    display: inline-block;
    border-radius: 4px;
    position: relative;
    cursor: zoom-in;

    &.editable {
      cursor: pointer;
      width: 400px;
      height: 300px;
      padding: 0;
      overflow: hidden;
    }
  }
}

.editable {
  border-radius: 4px;
  border: solid 1px @color-team-yellow;

  &::-webkit-input-placeholder {
    color: #a3a3a3;
  }

  &.error {
    border-color: @color-primary-red;
  }

  &:disabled {
    color: #bdbdbd;
    border-color: lighten(@color-team-yellow, 20%);
  }
}

.reward-modal {
  .modal-dialog {
    width: 500px;
    height: 600px;

    .modal-content {
      height: 100%;

      .modal-body {
        height: 100%;
        padding: 0;
        background-size: cover;
        background-repeat: no-repeat;

        .reward-container {
          width: 100%;
          height: 100%;
          display: flex;
          flex: 1;
          flex-direction: column;
          background: rgba(0, 0, 0, 0.6);

          .reward-background {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 0;
            content: '';
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: cover;
            background-color: @color-dark-grey;
            .animation(fade-in 0.5s);

            &::after {
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 1040;
              background-color: rgba(0, 0, 0, 0.8);
              content: '';
              .animation(fade-in 0.5s);
            }
          }

          .reward-content {
            z-index: 1;
            height: 100%;
          }

          .reward-wrapper {
            display: flex;
            flex-direction: column;
            padding: 50px;
            height: 100%;

            .reward-title {
              text-transform: uppercase;
              letter-spacing: 2px;
              width: 100%;
              .font-m1();
              .type-secondary();

              &.editable {
                display: block;
                margin: 25px auto 1rem;
                color: white;
                text-align: center;
                background: rgba(255, 255, 255, 0.2);
              }
            }

            .reward-text {
              color: white;
              line-height: 1.5em;
              width: 100%;

              &.editable {
                overflow: auto auto;
                font-family: 'NotoSerif', 'CaslonTwoTwentyFour-Bold', Georgia, Times, 'Times New Roman', serif;
                font-size: 2rem;
                display: block;
                margin: 0 auto;
                text-align: center;
                background: rgba(255, 255, 255, 0.2);
              }
            }

            .btn {
              margin-top: 50px;
              display: inline-block;
              height: 50px;
              width: 50px;
              overflow: hidden;
              font-size: 20px;
              border: solid 1px white;
              color: white;
              background-color: rgba(255, 255, 255, 0);
              opacity: 0;
              outline: none;
              .border-radius(50%);

              .animation(rotate-in 1s forwards);
              .animation-delay(0.25);

              &::after {
                content: "\e96a";
                margin-left: 3px;
              }

              .transition(background-color 0.5s, color 0.5s);

              &:hover {
                background-color: rgba(255, 255, 255, 1);
                color: black;
              }

              &:active,
              &:focus {
                outline: none;
              }

              &.editing {
                &::before {
                  content: "\e958";
                  margin-left: 1px;
                }

                &::after {
                  content: "";
                }
              }
            }
          }
        }
      }
    }
  }
}

// current chapter - asking for user number

.alert-widget {
  margin-top: 1.5rem;
  background: rgba(255, 255, 255, 0.8);
  border: solid 1px white;
  color: @color-full;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  line-height: 1.7rem;

  .close {
    right: auto;
  }

  .btn {
    margin-top: 1px;
  }

  .form-group {
    margin: 0.5rem 0 0;
  }
}

// used in workbook

.donutchart-track {
  fill: transparent;
  stroke: @color-rare;
  stroke-width: 25;
}

.donutchart-indicator {
  fill: transparent;
  stroke: @color-personal-blu;
  stroke-width: 25;
  stroke-dasharray: 0 10000;
  transition: stroke-dasharray 0.3s ease;
}

.donutchart {
  margin: 0 auto;
  border-radius: 50%;
  display: block;
}

.donutchart-text {
  fill: #6d6d6d;
}

.donutchart-text-val {
  font-size: 30px;
}

.donutchart-text-percent {
  font-size: 20px;
}

.donutchart-text-label {
  font-size: 13px;
}

// funky radios - used in progrma details

.funkyradio div {
  clear: both;
  overflow: hidden;
}

.funkyradio label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #d1d3d4;
  font-weight: normal;
}

.funkyradio input[type="radio"]:empty,
.funkyradio input[type="checkbox"]:empty {
  display: none;
}

.funkyradio input[type="radio"]:empty ~ label,
.funkyradio input[type="checkbox"]:empty ~ label {
  position: relative;
  line-height: 2.2rem;
  text-indent: 3.25em;
  margin-bottom: 0.5rem;
  padding-top: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.funkyradio input[type="radio"]:empty ~ label::before,
.funkyradio input[type="checkbox"]:empty ~ label::before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: '';
  width: 2.5em;
  background: #d1d3d4;
  border-radius: 3px 0 0 3px;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label::before,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label::before {
  content: '\2714';
  text-indent: 0.9em;
  color: #c2c2c2;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label {
  color: #888;
}

.funkyradio input[type="radio"]:checked ~ label::before,
.funkyradio input[type="checkbox"]:checked ~ label::before {
  content: '\2714';
  text-indent: 0.9em;
  color: @color-full;
  background-color: @color-rare;
}

.funkyradio input[type="radio"]:checked ~ label,
.funkyradio input[type="checkbox"]:checked ~ label {
  color: #777;
}

.funkyradio input[type="radio"]:focus ~ label::before,
.funkyradio input[type="checkbox"]:focus ~ label::before {
  box-shadow: 0 0 0 3px #999;
}

.funkyradio-default input[type="radio"]:checked ~ label::before,
.funkyradio-default input[type="checkbox"]:checked ~ label::before {
  color: @color-full;
  background-color: @color-rare;
}

.funkyradio-primary input[type="radio"]:checked ~ label::before,
.funkyradio-primary input[type="checkbox"]:checked ~ label::before {
  color: #fff;
  background-color: #337ab7;
}

.funkyradio-success input[type="radio"]:checked ~ label::before,
.funkyradio-success input[type="checkbox"]:checked ~ label::before {
  color: #fff;
  background-color: #70b9bf;
  padding-top: 2px;
}

.funkyradio-danger input[type="radio"]:checked ~ label::before,
.funkyradio-danger input[type="checkbox"]:checked ~ label::before {
  color: #fff;
  background-color: #d9534f;
}

.funkyradio-warning input[type="radio"]:checked ~ label::before,
.funkyradio-warning input[type="checkbox"]:checked ~ label::before {
  color: #fff;
  background-color: #f0ad4e;
}

.funkyradio-info input[type="radio"]:checked ~ label::before,
.funkyradio-info input[type="checkbox"]:checked ~ label::before {
  color: #fff;
  background-color: #5bc0de;
}

// reddot
.redDot {
  position: absolute;
  right: 15px;
  top: 15px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: @color-primary-red;
}

.redTag {
  position: absolute;
  display: inline-block;
  background: @color-primary-red;
  color: white;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 0.25rem 0.625rem;
  padding-top: 0.375rem;

  &.topRight {
    right: 0;
    top: 15px;
  }

  &.bottomRight {
    right: 20px;
    bottom: 15px;
  }

  &.bottomLeft {
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

// separator

.divider-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;

  &::before {
    content: '';
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &::after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 5px 15px;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    background-color: @color-personal-blu;
    color: white;
    border-radius: 20px;
  }
}

.simple-divider {
  color: @color-dark-grey;
  margin: 1.2rem 0 1.5rem;
  text-align: center;
  font-weight: bold;
  font-size: 0.85rem;

  span {
    padding: 0.4rem 1rem;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  }
}

.profile-img {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-color: @color-brown-lighter;
}

// spinner

.spinner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  background-color: @color-full;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

// slider
.slider {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.84, -0.26, 0.3, 1.5);
  background: rgba(255, 255, 255, 0.9);
  background-size: cover;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  &.close {
    top: 100vh;
    height: 0;
  }

  &.success {
    background-color: #2d9789;
    background-image: url('/app/common/images/backgrounds/success.svg');

    h1,
    h2,
    h3 {
      color: white;
    }

    color: white;
  }

  &.fail {
    background-color: #f56267;
    background-image: url('/app/common/images/backgrounds/fail.svg');

    h1,
    h2,
    h3 {
      color: white;
    }

    color: white;
  }

  &.progress {
    background: #333e69;
    color: white;
    background-image: url('/app/common/images/backgrounds/evaluating.svg');
    background-repeat: no-repeat;
    background-size: 100%;

    h1,
    h2,
    h3 {
      color: white;
    }
  }
}

// video player

.video-js {
  font-size: 11px;
  color: #fff;
}

.vjs-default-skin .vjs-big-play-button {
  font-size: 3em;
  line-height: 2em;
  height: 2em;
  width: 3em;
  border: 1.2px solid #fff;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  margin-left: -1.5em;
  margin-top: -1em;
}

.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95);
  color: #ffac1e;
}

.video-js .vjs-slider {
  background-color: #ffac1e;
}

.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
  background: #ffac1e;
  color: #ffac1e;
}

.video-js .vjs-load-progress {
  background: #ffac1e;
  color: #ffac1e;
}

.video-js .vjs-load-progress div {
  background: #ffac1e;
  color: #ffac1e;
}

.video-js .vjs-resize-manager {
  display: none;
}

// abbr {{explain}}

abbr.explainer {
  text-decoration: none;
  border-bottom: solid 2px @color-primary-red;
  color: @color-primary-red;
}

// custom Quill css

.quill {
  .ql-toolbar.ql-snow {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-color: transparent;
    border-bottom: solid 1px @color-team-yellow;
    background: #fff9ed;
  }

  &.error {
    .ql-toolbar.ql-snow {
      border-color: red;
      background: #ffe8e8;
    }

    .ql-container.ql-snow {
      border-color: @color-primary-red;
      background: #fff8f7;
    }
  }

  .ql-container.ql-snow {
    font-family: 'NotoSans', 'NeueHaasUnicaPro', "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border-color: transparent;
    font-size: 1rem;
    background: white;
    transition: background-color 0.2s;
    min-height: 5rem;

    .active & {
      background: #fffdfb;
    }

    abbr {
      text-decoration: none;
      border-bottom: solid 2px @color-primary-red;
      color: @color-primary-red;
    }

    &.ql-disabled {
      color: #bdbdbd;
      border-color: #ddd;
    }
  }

  &.disabled {
    .ql-toolbar.ql-snow {
      border-color: #ddd;
      background: #f9f9f9;
    }
  }
}

.modal-body {
  .quill {
    .ql-container {
      .ql-editor {
        max-height: 250px;
      }
    }
  }
}

.import-original {
  .quill {
    .ql-container.ql-snow {
      &.ql-disabled {
        color: #666;
      }
    }
  }
}

// custom popover

.explainer {
  cursor: help;
}

.gnowbe-popover {
  display: inline-block;
  background-color: @color-dark-grey;
  color: white;
  position: absolute;
  border-radius: 4px;
  max-width: 250px;
  transition: opacity 0.2s;
  opacity: 0;
  z-index: 1000;

  div {
    margin: 7px 10px 5px;
  }

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 25px;
    left: 50%;
    margin-left: -5px;
    background: @color-dark-grey;
    transform: rotate(45deg);
  }
}

// add to notes

.select-tools {
  list-style: none;
  background: @color-dark-grey;
  border-radius: 4px;
  position: absolute;
  transition: opacity 0.3s, left 0.2s, top 0.2s;
  z-index: 1000;

  li {
    display: inline-block;
    color: white;
    padding: 7px 0;
    width: 114px;
    text-align: center;
    cursor: pointer;
  }

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 29px;
    left: 50%;
    margin-left: -5px;
    background: @color-dark-grey;
    transform: rotate(45deg);
  }
}

.no-padding-left {
  padding-left: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

///// custom toggles

.tgl-wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0;

  .tgl-label {
    margin-top: 2px;
    margin-bottom: 0;

    &.disabled {
      cursor: not-allowed;
      color: #d9d9d9;
    }
  }

  &.disabled {
    filter: grayscale(50%);
  }
}

.tgl {
  display: none;

  &,
  &::after,
  &::before,
  & *,
  & *::after,
  & *::before,
  & + .tgl-btn {
    box-sizing: border-box;

    &::selection {
      background: none;
    }
  }

  + .tgl-btn {
    outline: 0;
    display: block;
    width: 3em;
    height: 2em;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-bottom: 0;

    &::after,
    &::before {
      position: relative;
      display: block;
      content: "";
      width: 65%;
      height: 100%;
    }

    &::after {
      left: 0;
    }

    &::before {
      display: none;
    }
  }

  &:checked + .tgl-btn::after {
    left: 36%;
  }
}

.tgl-light {
  + .tgl-btn {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.3s ease;

    &::after {
      border-radius: 50%;
      background: #fff;
      transition: all 0.3s ease;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  &:checked + .tgl-btn {
    background: @color-light-green;
  }
}

.tgl-flip {
  + .tgl-btn {
    padding: 2px;
    transition: all 0.2s ease;
    font-family: sans-serif;
    perspective: 100px;

    &::after,
    &::before {
      display: inline-block;
      transition: all 0.4s ease;
      width: 100%;
      text-align: center;
      position: absolute;
      line-height: 2em;
      font-weight: bold;
      color: #fff;
      top: 0;
      left: 0;
      backface-visibility: hidden;
      border-radius: 4px;
    }

    &::after {
      content: attr(data-tg-on);
      background: #02c66f;
      transform: rotateY(-180deg);
    }

    &::before {
      background: #ff3a19;
      content: attr(data-tg-off);
    }

    &:active::before {
      transform: rotateY(-20deg);
    }
  }

  &:checked + .tgl-btn {
    &::before {
      transform: rotateY(180deg);
    }

    &::after {
      transform: rotateY(0);
      left: 0;
      background: #7fc6a6;
    }

    &:active::after {
      transform: rotateY(20deg);
    }
  }
}

// separator

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 8px 0;
}

.hr-sect::before,
.hr-sect::after {
  content: "";
  flex-grow: 1;
  background: white;
  height: 1px;
  font-size: 0;
  line-height: 0;
  margin: 0 8px;
}

.hr-sect-gray {
  @apply text-deepgray;

  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin: 8px 0;
}

.hr-sect-gray::before,
.hr-sect-gray::after {
  @apply bg-deepgray;

  content: "";
  flex-grow: 1;
  height: 1px;
  font-size: 0;
  line-height: 0;
  margin: 0 8px;
}

// bubble

.gnowbe-bubble {
  background: #eee8d9;
  border-radius: 4px;
  position: relative;
  padding: 15px;
  word-wrap: break-word;
  font-size: 0.9rem;
  max-width: 270px;

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: -5px;
    background: #eee8d9;
    transform: rotate(45deg);
  }
}

.chat-with-curators {
  .chat-messages {
    margin: 20px 0;
    line-height: 1.3rem;

    &.simple {
      .simple-message {
        .timestamp {
          display: inline-block;
          margin-left: 10px;
          color: silver;
          font-size: 0.8rem;
        }

        .icon-trash {
          float: right;
          font-size: 12px;
          margin-top: 2px;
          color: silver;
          opacity: 0;
          transition: 0.3s;
          cursor: pointer;
        }
      }

      &:hover {
        .icon-trash {
          opacity: 1;

          &:hover {
            color: @color-primary-red;
          }
        }
      }
    }
  }

  .messageInputForm {
    margin-top: 20px;

    .messageInput__input {
      border: none;
      border-bottom: solid 1px silver;
      padding: 0;
      width: 100%;
      resize: none;
    }
  }
}

/// loader

.loader-overlay {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1060;

  .loader {
    color: @color-primary-red;
    display: block;
  }

  // &::after {
  //   //.animation(fade-in, .5s); // tole dela, ce dam .transition(opacity, .5s) pa nic :(
  //   .transition(opacity .5s);
  //   display: block;
  //   opacity: 1;
  // }
}

// empty stuff box
.info-box {
  text-align: center;
  margin: 150px auto 0;
  background-color: white;
  padding: 50px 20px;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  width: 500px;
}

// error boundary box
.error-box {
  background-color: white;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
}

.action-tags {
  position: absolute;
  right: 15px;
  bottom: 15px;
  display: flex;

  span {
    margin-left: 5px;
    position: relative;
  }

  .red {
    background: @color-primary-red;
  }

  .gray {
    background: @color-dark-grey;
  }

  .blue {
    background: @color-personal-blu;
  }

  .orange {
    background: @color-orange;
  }
}

///

.show-likes-button {
  overflow: hidden;

  a {
    color: @color-brown;
    transition: 0.2s;

    &:hover {
      color: @color-primary-red;
    }
  }
}

.separator-2 {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 0.8rem;
  color: @color-medium-well;
}

//// saving indicator
.save-indicator {
  width: 130px;
  position: fixed;
  top: 1rem;
  left: 50%;
  margin-left: -65px;
  text-align: center;
  color: white;
  border-radius: 20px;
  padding: 7px 10px;
  z-index: 9999;

  &.delayed-visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.25s linear;
  }

  &.delayed-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1.5s, opacity 0.25s 1.25s linear;
  }
}

///

.category-separator {
  font-size: 12px;
  color: @color-medium-well;
  text-transform: uppercase;
  margin: 20px 0 10px;
  letter-spacing: 0.5px;
  font-weight: bold;
}

///

#chapter-details-tabs {
  margin-top: 1em;

  .nav.nav-tabs {
    margin-left: 0;

    a {
      color: @color-dark-grey;

      &:hover {
        background: #fafafa;
      }
    }

    a.active {
      background: #f9f9f9;
      font-weight: bold;
    }

    a.disabled {
      color: @color-rare;
    }
  }

  .tab-content {
    padding: 1px 10px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-top: none;
  }
}

////

.btn {
  transition: background-color 0.05s;

  @apply py-2 px-3 rounded;

  &.btn-info {
    color: @color-dark-grey;
    background-color: @color-personal-blu;
  }

  &.btn-default {
    background: white;
    border: solid 1px darken(white, 20%);
    color: @color-dark-grey;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background: darken(white, 5%);
        border: solid 1px darken(white, 30%);
      }

      &:active,
      &.active {
        background-color: darken(white, 10%);
        color: @color-dark-grey;
        border: solid 1px darken(white, 40%);
      }
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      background: darken(white, 5%);
      border: solid 1px darken(white, 30%);
    }
  }

  &.checkmark,
  &.back {
    &:focus {
      box-shadow: none;

      &:active {
        box-shadow: none;
      }
    }
  }

  &.btn-primary {
    color: @color-dark-grey;
    background-color: @color-personal-blu;
    border-color: @color-personal-blu;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: darken(@color-personal-blu, 8%);
        border-color: darken(@color-personal-blu, 8%);
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem lighten(@color-personal-blu, 25%);
      }

      &:active {
        color: @color-dark-grey;
        background-color: darken(@color-personal-blu, 10%);
        border-color: darken(@color-personal-blu, 10%);

        &:focus {
          box-shadow: 0 0 0 0.2rem lighten(@color-personal-blu, 20%);
        }
      }
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      border-color: lighten(@color-personal-blu, 15%);
      background-color: lighten(@color-personal-blu, 15%);
    }
  }

  &.btn-danger {
    background-color: @color-primary-red;
    border-color: @color-primary-red;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: darken(@color-primary-red, 8%);
        border-color: darken(@color-primary-red, 8%);
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem lighten(@color-primary-red, 25%);
      }

      &:active {
        background-color: darken(@color-primary-red, 10%);
        border-color: darken(@color-primary-red, 10%);
      }
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      border-color: lighten(@color-primary-red, 15%);
      background-color: lighten(@color-primary-red, 15%);
    }
  }

  &.btn-warning {
    background-color: @color-team-yellow;
    border-color: @color-team-yellow;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: darken(@color-team-yellow, 10%);
        border-color: darken(@color-team-yellow, 10%);
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem lighten(@color-team-yellow, 25%);
      }

      &:active {
        background-color: darken(@color-team-yellow, 12%);
        border-color: darken(@color-team-yellow, 12%);
      }
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      border-color: lighten(@color-team-yellow, 15%);
      background-color: lighten(@color-team-yellow, 15%);
    }
  }
}

.color-red {
  color: @color-primary-red;
}

.alert-box {
  background: @color-primary-red;
  padding: 10px 15px;
  color: white;
  margin-top: 10px;
  border-radius: 4px;
}

.data-placeholder {
  max-width: 200px;
  min-height: 25px;
  background-color: @color-raw;
  border-radius: 20px;

  .animated-background {
    border-radius: 20px;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, @color-raw 0%, #ddd 18%, @color-raw 33%);
    background-size: 300px 104px;
    height: 25px;
    position: relative;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 0 0
  }

  100% {
    background-position: 200px 0
  }
}

.main-container .session-block {
  .size-tree {
    margin-bottom: 1rem;
    list-style: none;

    ol {
      margin-bottom: 1rem;
      list-style: none;
      margin-left: 0;
    }

    li {
      padding-bottom: 5px;
    }
  }
}

.collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  cursor: pointer;
}

.has-appendum {
  display: flex;
  align-items: center;
  background: #fff9ed;
  border: solid 1px @color-team-yellow;
  border-radius: 4px;

  .editable {
    border: none;
    border-right: solid 1px @color-team-yellow;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:disabled {
      color: #bdbdbd;
      border-color: lighten(@color-team-yellow, 20%);
    }
  }

  .appension {
    padding: 0 10px;
  }
}

.ql-editor {
  line-height: 1.7rem;
}

.ql-editor.ql-blank::before {
  font-style: normal;
}

// quill indents
.ql-editor,
.action-description,
.session-description,
.course-description {
  ol,
  ul {
    padding-left: 1.5rem;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

    li::before {
      margin-left: -2rem;
      margin-right: 0.4rem;
      display: inline-block;
      width: 1.5rem;
      text-align: right;
    }
  }

  ol > li,
  ul > li {
    list-style-type: none;
    margin: 0
  }

  ul > li::before { content: "\2022"; }

  ul li,
  ol li { padding-left: 1rem }
  ol li { counter-increment: list-0; counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 }
  ol li::before { content: counter(list-0, decimal) ". " }
  ol li.ql-indent-1 { counter-increment: list-1; counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 }
  ol li.ql-indent-1::before { content: counter(list-1, lower-alpha) ". " }
  ol li.ql-indent-2 { counter-increment: list-2; counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9 }
  ol li.ql-indent-2::before { content: counter(list-2, lower-roman) ". " }
  ol li.ql-indent-3 { counter-increment: list-3; counter-reset: list-4 list-5 list-6 list-7 list-8 list-9 }
  ol li.ql-indent-3::before { content: counter(list-3, decimal) ". " }
  ol li.ql-indent-4 { counter-increment: list-4; counter-reset: list-5 list-6 list-7 list-8 list-9 }
  ol li.ql-indent-4::before { content: counter(list-4, lower-alpha) ". " }
  ol li.ql-indent-5 { counter-increment: list-5; counter-reset: list-6 list-7 list-8 list-9 }
  ol li.ql-indent-5::before { content: counter(list-5, lower-roman) ". " }
  ol li.ql-indent-6 { counter-increment: list-6; counter-reset: list-7 list-8 list-9 }
  ol li.ql-indent-6::before { content: counter(list-6, decimal) ". " }
  ol li.ql-indent-7 { counter-increment: list-7; counter-reset: list-8 list-9 }
  ol li.ql-indent-7::before { content: counter(list-7, lower-alpha) ". " }
  ol li.ql-indent-8 { counter-increment: list-8; counter-reset: list-9 }
  ol li.ql-indent-8::before { content: counter(list-8, lower-roman) ". " }
  ol li.ql-indent-9 { counter-increment: list-9 }
  ol li.ql-indent-9::before { content: counter(list-9, decimal) ". " }
  .ql-indent-1:not(.ql-direction-rtl) { padding-left: 3rem }
  li.ql-indent-1:not(.ql-direction-rtl) { padding-left: 3rem }
  .ql-indent-2:not(.ql-direction-rtl) { padding-left: 5rem }
  li.ql-indent-2:not(.ql-direction-rtl) { padding-left: 5rem }
  .ql-indent-3:not(.ql-direction-rtl) { padding-left: 7rem }
  li.ql-indent-3:not(.ql-direction-rtl) { padding-left: 7rem }
  .ql-indent-4:not(.ql-direction-rtl) { padding-left: 9rem }
  li.ql-indent-4:not(.ql-direction-rtl) { padding-left: 9rem }
  .ql-indent-5:not(.ql-direction-rtl) { padding-left: 11rem }
  li.ql-indent-5:not(.ql-direction-rtl) { padding-left: 11rem }
  .ql-indent-6:not(.ql-direction-rtl) { padding-left: 13rem }
  li.ql-indent-6:not(.ql-direction-rtl) { padding-left: 13rem }
  .ql-indent-7:not(.ql-direction-rtl) { padding-left: 15rem }
  li.ql-indent-7:not(.ql-direction-rtl) { padding-left: 15rem }
  .ql-indent-8:not(.ql-direction-rtl) { padding-left: 17rem }
  li.ql-indent-8:not(.ql-direction-rtl) { padding-left: 17rem }
  .ql-indent-9:not(.ql-direction-rtl) { padding-left: 19rem }
  li.ql-indent-9:not(.ql-direction-rtl) { padding-left: 19rem }
}

@media (max-width: 640px) {
  .zEWidget-launcher {
    display: none !important;
  }

  .main-container .chapter-list-container {
    right: 0 !important;
    left: unset !important;
    transform: translateX(940px) !important;

    &.visible {
      right: 0 !important;
      left: unset !important;
      direction: rtl;
      transform: unset !important;

      .chapter-list {
        direction: ltr;
      }
    }
  }
}

// skeleton loading boxes

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;

  @apply rounded-md;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image:
      linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
      );
    animation: shimmer 3s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.content-overlay {
  &.in {
    visibility: visible;
    opacity: 0.5;
    transition: opacity 0.2s linear;
  }

  &.out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
  }
}

.uppload-modal {
  .uppload-service {
    button.uppload-button,
    form button {
      border-radius: 0.5rem;
      padding: 0.75rem 1.25rem;
    }

    form input {
      width: unset;
    }
  }
}

.cute-tooltip {
  .cute-tooltip-body {
    background: #047481;
  }

  .cute-tooltip-caret {
    clip: rect(7px 17px 17px -3px);

    &::before {
      background: #047481;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      content: "";
      display: block;
      height: 14px;
      transform: rotate(45deg);
      width: 14px;
    }
  }
}

.service-icon svg {
  display: inline-block;
}

.form-input,
.form-select {
  @apply border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md py-2 px-3 shadow-sm;

  &.border-team {
    @apply focus:ring-blue-300 focus:border-blue-300 !important;
  }
}

.react-date-picker__inputGroup__input {
  padding: 0 0.5rem;
  background-color: transparent;
}
// Date picker override
.react-date-picker__wrapper {
  padding: 5px;

  @apply bg-white border-team rounded-md;

  .react-date-picker__inputGroup__input {
    padding: 0 0.25rem;
    background: transparent;

    &:focus {
      @apply ring-blue-300 border-blue-300 rounded-sm;
    }
  }
}

.Toastify__toast {
  @apply pl-4 py-2;
}

// .Toastify__toast--success {
//   @apply !bg-actions-read;
// }

// home

.alice-carousel__stage-item {
  max-width: 16rem;
  margin-right: 1rem;
}

.no-max-width .alice-carousel__stage-item {
  max-width: none;
  margin-right: 0;
}

//

.sidebar-item {
  @apply flex w-full px-4 border-l-4 transition-none items-center py-2 font-medium text-gray-700 border-transparent;

  &.selected {
    @apply text-actions-multiple_choice border-actions-multiple_choice;

    &:focus {
      @apply text-actions-multiple_choice border-actions-multiple_choice;
    }
  }

  &:focus {
    @apply text-actions-multiple_choice outline-none ring-2 ring-offset-1 ring-actions-multiple_choice;
  }

  .icon {
    @apply inline w-6 text-lg font-bold -mt-1;
  }

  span {
    @apply ml-3 font-bold whitespace-nowrap border-b-2;
  }
}

.btn-v2 {
  @apply border rounded-md px-6 py-3 font-bold text-sm text-deepgray;

  &:focus {
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  &:hover {
    &:not(.disabled) {
      @apply hover:shadow-md;
    }
  }

  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  &.primary {
    @apply border-lake bg-lake;

    &:not(.disabled) {
      @apply hover:text-deepgray hover:bg-lake-lightish hover:border-lake-lightish focus:bg-lake-lightish focus:ring-lake-darker;
    }
  }

  &.team {
    @apply border-team bg-team;

    &:not(.disabled) {
      @apply hover:bg-yellow-400 hover:border-yellow-400 focus:ring-yellow-500;
    }
  }

  &.edit {
    @apply border-team;

    &:not(.disabled) {
      @apply focus:ring-yellow-500;
    }
  }

  &.quote {
    @apply border-actions-quote bg-actions-quote;

    &:not(.disabled) {
      @apply hover:bg-yellow-300 hover:border-yellow-300 focus:ring-yellow-400;
    }
  }

  &.danger {
    @apply border-coral bg-coral;

    &.disabled {
      @apply text-white;
    }

    &:not(.disabled) {
      @apply hover:bg-red-600 hover:border-red-600 text-white focus:ring-red-700;
    }
  }

  &.sub {
    @apply border-actions-multiple_choice bg-actions-multiple_choice text-white;

    &:not(.disabled) {
      @apply hover:opacity-95 focus:ring-green-900;
    }
  }

  &.default {
    @apply bg-white border-gray-400;

    &:not(.disabled) {
      @apply hover:opacity-95 focus:ring-gray-500;
    }
  }

  &.chat {
    @apply border-teak text-teak;

    &:not(.disabled) {
      @apply hover:opacity-95 focus:ring-teak;
    }
  }
  &.magic {
    @apply border-magic bg-magic text-white;

    &:not(.disabled) {
      @apply hover:bg-magic/90 hover:border-magic/90 focus:bg-magic/90 focus:ring-magic;
    }
  }
}

.custom-dropdown {
  @apply py-1 origin-top-right bg-white border border-gray-200 rounded-md shadow-lg outline-none;
}

.new-chapter-item {
  @apply relative;

  &.dashed {
    &::before {
      @apply border-dashed;
    }
  }

  &.editing {
    &::before {
      @apply border-none;
    }
  }

  &.last {
    &::before {
      // @apply border-transparent;
      height: 52%;
      transform: translateY(-50%);
    }
  }

  &::before {
    content: '';
    margin-left: 1.13rem;

    @apply absolute border-l border-actions-multiple_choice left-0 h-full;
  }
}

.truncate-overflow {
  --max-lines: 4;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem;

  &::before {
    position: absolute;
    content: '...';
    bottom: 0;
    right: 1rem;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 1rem;
    width: 1rem;
    height: 1rem;
    background: #fff;
    z-index: 0;
  }
}

.reactour__popover {
  min-height: 11rem;
  min-width: 350px;
}

.form-group {
  @apply mb-4;
}

.react-calendar__tile--active {
  background: #42a7a9 !important;
  border-radius: 5px;
}