.main-container {
  .chapter-list-container {
    position: fixed;
    opacity: 0;
    top: 60px;
    left: 0;
    background: @color-dark-grey;
    height: 96%;
    width: 300px;
    z-index: 1050;
    box-sizing: border-box;
    padding: 1em;
    overflow: auto;
    .transition(transform 0.5s, opacity 0.3s);
    .translateX(-24rem);

    &.visible {
      opacity: 1;
      .translateX(0);
    }

    h3 {
      color: white;
      font-size: 1em;
      padding-bottom: 15px;
      margin-top: 0.5em;
      border-bottom: solid 1px white;
      .type-bold();
    }

    .chapter-list {
      .dropdown {
        a {
          padding-top: 4px;
        }
      }

      .dropdown-delete {
        color: @color-primary-red;
        transition: none;

        &:hover {
          background: @color-primary-red;
          color: white;
        }

        &:focus,
        &:active {
          background: darken(@color-primary-red, 10%);
          color: white;
        }
      }

      .open {
        .btn-edit {
          background: lighten(@color-team-yellow, 20%);
          border: solid 1px lighten(@color-team-yellow, 20%);
        }
      }

      .btn-edit {
        font-family: 'icomoon', sans-serif;
        color: @color-team-yellow;
        z-index: 1;
        font-size: 1.5rem;
        transform: rotate(90deg);
        padding: 0 0.5rem;

        &:hover {
          color: darken(@color-team-yellow, 15%);
        }

        &::before {
          content: '\e982';
          display: inline-block;
        }

        &::after {
          border: none;
          // border-radius: 100%;
          // background: darken(@color-team-yellow, 10%);
          // border: solid 1px darken(@color-team-yellow, 10%);
          // opacity: 0;
          // transition: opacity 0.2s;
          // .pseudo-position();
        }

        // &::after {
        //   border-radius: 100%;
        //   background: darken(@color-team-yellow, 10%);
        //   border: solid 1px darken(@color-team-yellow, 10%);
        //   opacity: 0;
        //   transition: opacity 0.2s;
        //   .pseudo-position();
        // }
      }

      & > li {
        padding-top: 15px;
        margin-left: -5px;
        padding-left: 5px;
        position: relative;

        // &:after {
        //   content: '';
        //   width: 92%;
        //   height: 1px;
        //   background-color: @color-medium;
        //   display: block;
        //   margin-top: 1em;
        //   margin-left: 20px;
        // }

        &:last-child::after {
          content: none;
        }

        .icon-team-filled {
          color: @color-team-yellow;
        }

        .chapter-number {
          position: relative;
          text-transform: uppercase;
          font-size: 0.8em;
          letter-spacing: 1.25px;
          margin-top: 3px;
          padding-left: 22px;
          color: @color-medium;
          .type-secondary();

          &.active {
            color: @color-personal-blu;
          }

          .tag {
            position: absolute;
            right: 0;

            &.new {
              color: @color-personal-blu;
            }

            &.updated {
              color: @color-team-yellow;
            }
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      .chapter {
        .team-icon {
          display: flex;
          float: right;
          position: absolute;
          right: 0;
          top: 22px;
          height: 24px;
          justify-content: center;
          align-items: center;

          .edit-dropdown {
            margin-left: 10px;

            .btn-edit {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
              height: 22px;
              width: 22px;
              border-radius: 15px;
            }
          }
        }
      }

      .chapter-title {
        margin-right: 2em;
        color: @color-medium;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .circle {
          position: relative;
          display: inline-block;
          border: 1px solid @color-medium;
          width: 12px;
          height: 12px;
          margin-right: 10px;
          border-radius: 50%;
          min-width: 12px;

          &.enabled {
            border-color: @color-personal-blu;

            .circle-fill {
              background: fade(@color-personal-blu, 40%);
              width: 100%;
              //height: 35%;
              display: block;
              position: absolute;
              content: '';
              bottom: 0;
            }
          }

          &.enabled.active {
            border-color: @color-personal-blu;

            .circle-fill {
              background: fade(@color-personal-blu, 40%);
            }
          }

          &.completed {
            background: white;
            border: none;
            color: darken(@color-image, 20%);

            .circle-fill {
              border-radius: 100%;
            }

            &::after {
              font-family: 'icomoon', sans-serif !important;
              content: "\e964";
              position: absolute;
              font-size: 1rem;
              top: -5px;
              left: 0;
            }
          }
        }

        &.active {
          color: @color-personal-blu;
        }

        &.enabled {
          color: #fff;
        }
      }

      hr {
        border-color: @color-medium;
        margin: 0;
        margin-left: 22px;
        // padding-top: 15px;
        margin-top: 15px;
      }

      .truncate {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .button-new {
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      border: solid 1px @color-medium;
      text-transform: uppercase;
      font-weight: bold;
      padding: 1.8rem 1rem 1.6rem;
      color: @color-medium;
      margin-bottom: 3rem;
      margin-top: 1rem;
      font-size: 0.9rem;
      background: rgba(0, 0, 0, 0.1);

      &:hover {
        border-color: #bababa;
        color: #bababa;
      }

      &:active {
        border-color: @color-team-yellow;
        color: @color-team-yellow;
        background: rgba(255, 182, 55, 0.2);
      }
    }
  }
}

.chapterSortable {
  z-index: 9999;
  padding-top: 15px;
  margin-left: -5px;
  padding-left: 5px;
  position: relative;
  list-style: none;

  // &:after {
  //   content: '';
  //   width: 92%;
  //   height: 1px;
  //   background-color: @color-medium;
  //   display: block;
  //   margin-top: 1em;
  //   margin-left: 20px;
  // }

  &:last-child::after {
    content: none;
  }

  .icon-team-filled {
    color: @color-team-yellow;
  }

  .chapter-number {
    position: relative;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 1.25px;
    margin-top: 3px;
    padding-left: 22px;
    color: @color-medium;
    .type-secondary();

    &.active {
      color: @color-personal-blu;
    }

    .tag {
      position: absolute;
      right: 0;

      &.new {
        color: @color-personal-blu;
      }

      &.updated {
        color: @color-team-yellow;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  .team-icon {
    display: none;
    float: right;
    position: absolute;
    right: 0;
    top: 22px;
    height: 24px;
    justify-content: center;
    align-items: center;

    .edit-dropdown {
      margin-left: 10px;

      .btn-edit {
        padding: 2px 7px 0;
        border-radius: 15px;
      }
    }
  }

  .chapter-title {
    margin-right: 2em;
    color: @color-medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .circle {
      position: relative;
      display: inline-block;
      border: 1px solid @color-medium;
      width: 12px;
      height: 12px;
      margin-right: 10px;
      border-radius: 50%;
      min-width: 12px;

      &.enabled {
        border-color: @color-personal-blu;

        .circle-fill {
          background: fade(@color-personal-blu, 40%);
          width: 100%;
          //height: 35%;
          display: block;
          position: absolute;
          content: '';
          bottom: 0;
        }
      }

      &.enabled.active {
        border-color: @color-personal-blu;

        .circle-fill { background: fade(@color-personal-blu, 40%); }
      }

      &.completed {
        background: darken(@color-image, 20%);
        border-color: darken(@color-image, 20%);
      }
    }

    &.active { color: @color-personal-blu; }
    &.enabled { color: #fff; }
  }

  hr {
    border-color: @color-medium;
    margin: 0;
    margin-left: 22px;
    // padding-top: 15px;
    margin-top: 15px;
  }

  .truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dragChapterHandle {
  margin-right: 9px;
  color: @color-team-yellow;
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
