.workbook {
  ul,
  ol {
    margin: 0 0 0 2em;
  }

  ul { list-style: initial; }
  ol { list-style: decimal; }

  blockquote {
    border: solid 2px @color-dark-grey;
    color: @color-dark-grey;
    font-size: 14px;
    padding: 5px 1em;
    margin: 1em 0;
    overflow: hidden;
  }

  .date-separator {
    padding: 0;
    font-size: 0.9rem;
    line-height: 1rem;
    color: #2c2d30;
    font-weight: 700;
    text-align: center;
    cursor: default;
    clear: both;
    position: relative;
    .rem-px(margin-bottom, 15);

    &.more-margin {
      .rem-px(margin-top, 15);
    }

    hr {
      position: absolute;
      border-top: 1px dashed #e8e8e8;
      top: 0.75rem;
      right: 0;
      left: 0;
      margin: 0;
    }

    .date-line {
      display: block;
      position: relative;
      border: none;
      color: white;
      height: 0.05rem;
      background: black;
      background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 160, from(#000), to(#fff));
      .rem-px(margin-left, 10);
      .rem-px(margin-right, 10);
    }

    .date-title {
      background: #fff;
      padding: 0.25rem 0.75rem;
      display: inline-block;
      margin: 0 auto;
      position: relative;
      border-radius: 0.25rem;
      line-height: 2rem;
    }
  }

  .action-list {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    margin-left: 0;
  }

  .action-list-item {
    line-height: 25px;
    margin-bottom: 2em;
    margin-left: 0;
    list-style-type: none;

    .action-title {
      word-break: break-word;
    }

    .user-action {
      display: inline-block;
      margin-left: 1em;
      padding: 0 7px;
      border-radius: 3px;
      font-family: NotoSerif;
    }

    .action-description {
      // rtl
      // direction: rtl;
    }

    .capture-action {
      object-fit: cover;
      width: 400px;
      height: 400px;
      border-radius: 3px;

      @media screen and (max-width: 500px) {
        float: none;
        display: block;
        margin: 0 auto 30px;
      }
    }
  }

  .workbook-image {
    width: 200px;
    height: 200px;
    float: right;
    margin-left: 3em;
    margin-bottom: 2em;

    @media screen and (max-width: 500px) {
      float: none;
      display: block;
      margin: 0 auto 30px;
    }
  }

  .session-image {
    object-fit: cover;
    max-height: 400px;
    margin-bottom: 30px;
    border-radius: 3px;
    margin: auto;

    @media screen and (max-width: 500px) {
      float: none;
      display: block;
      margin: 0 auto 30px;
    }
  }

  .action-image {
    object-fit: cover;
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
    border-radius: 3px;
    float: right;
    margin-left: 30px;

    @media screen and (max-width: 500px) {
      float: none;
      display: block;
      margin: 0 auto 30px;
    }
  }

  .snippet {
    position: relative;
    padding: 15px;
    padding-right: 40px;
    line-height: 18px;
    margin: 1em 0;

    .content {
      line-height: 24px;
    }

    &.qa {
      background: #fef7f5;
      border-left: solid 4px @color-qa;

      .title {
        color: @color-qa;
      }
    }

    &.text {
      background: lighten(@color-text, 52%);
      border-left: solid 4px @color-text;

      .title {
        color: @color-text;
      }
    }

    &.photo {
      background: #f5f0f0;
      border-left: solid 4px @color-photo;

      .title {
        color: @color-photo;
      }
    }

    &.multiple_choice {
      background: #eef4f5;
      border-left: solid 4px @color-multiple_choice;

      .title {
        color: @color-multiple_choice;
      }
    }

    &.assessment {
      background: lighten(@color-assessment, 52%);
      border-left: solid 4px @color-assessment;

      .title {
        color: @color-assessment;
      }
    }

    &.image {
      background: lighten(@color-image, 52%);
      border-left: solid 4px @color-image;

      .title {
        color: @color-image;
      }
    }

    &.chapter {
      background: @color-raw;
      border-left: solid 4px @color-dark-grey;
    }
  }

  .meta {
    cursor: pointer;
    font-size: 12px;
    margin-top: 10px;
  }
}
