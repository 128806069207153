.messages,
.workbook {
  .item { .rem-px(margin-bottom, 5); .rem-px(margin-top, 15); }
}

.chat {
  ul,
  ol {
    margin: 0 0 0 1em;

    li { white-space: normal; }
  }

  ul { list-style: initial; }
  ol { list-style: decimal; }

  .chat-wrapper {
    margin-top: 1em;
    background: white;
    padding: 2em;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    max-height: 900px;
    height: calc(100vh - 8rem);
    height: calc(var(--vh, 1vh) * 100 - 8rem);

    .chat-container {
      display: flex;
      border: solid 1px @color-brown-lighter;
      border-color: #e6e7eb !important;

      .chat-userlist {
        @media screen and (max-width: 600px) {
          width: auto;
        }

        width: 40%;
        border-right: solid 1px @color-brown-lighter;
        overflow: auto;

        ul {
          margin: 0;

          li {
            display: flex;
            align-items: center;
            padding: 1em 1em 0.8em;
            z-index: 1;
            position: relative;

            &::after {
              background: lighten(@color-rare, 3%);
              transition: opacity 0.05s;
              opacity: 0;
              .pseudo-position();
            }

            &.selected {
              background: @color-rare;

              &::after {
                background: @color-rare !important;
              }
            }

            &:hover {
              cursor: pointer;

              &::after {
                opacity: 1;
              }
            }

            .avatar {
              margin-right: 0.5em;

              @media screen and (max-width: 600px) {
                margin: 0;
              }

              .profile-img {
                width: 50px;
                height: 50px;
              }
            }

            .info {
              @media screen and (max-width: 600px) {
                display: none;
              }

              flex: 1;
              display: flex;
              min-width: 0;
              flex-direction: column;
              padding-top: 2px;

              .nameAndTime {
                display: flex;

                .username {
                  color: @color-full;
                  flex: 1 1 0%;
                  // hack: https://css-tricks.com/flexbox-truncated-text/
                  min-width: 0;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }

                .time {
                  font-weight: normal;
                  font-size: 0.8rem;
                  margin-top: 1px;
                  color: #888;
                }
              }

              .message {
                color: @color-dark-grey;
                font-size: 0.9em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 2px;
                margin-left: 0;
              }
            }

            &.unread {
              font-weight: bold !important;

              .info {
                .nameAndTime {
                  .time {
                    color: @color-primary-red;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }

      .chat-presentation {
        flex: 1;
        display: flex;
        flex-direction: column;

        .chat-info {
          border-bottom: solid 1px #e5e7eb;
          text-align: center;
          padding: 1rem 1rem 0.78rem;
        }

        .chat-messages {
          flex: 1;
          padding: 1em 1em 0.8em;
          overflow: auto;
          background: #f9f7f2;

          &.simple {
            display: inline-block;
            margin-left: 10px;
            color: silver;
            font-size: 0.8rem;
          }

          .message-container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-top: 0.7em;

            &.same-user {
              margin-top: 0.2rem;

              .avatar {
                .profile-img {
                  display: none;
                }
              }
            }

            &:first-child {
              margin-top: 0;
            }

            .message {
              margin-top: 0;
              word-break: break-word;
              position: relative;
              box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);

              p {
                padding: 0;
                margin: 0;
                word-wrap: break-word;
              }

              pre {
                font-size: 0.9em;
                margin: 0 0 2px;
                border-radius: 10px;
              }

              code {
                font-size: 0.9em;
              }

              blockquote {
                padding: 0 5px;
                border-left-color: rgba(0, 0, 0, 0.3);
              }

              .message-dropdown-toggle {
                visibility: hidden;
                background: @color-personal-blu;
                padding: 0 5px;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                cursor: pointer;
              }

              &:hover {
                .message-dropdown-toggle {
                  visibility: visible;
                }
              }
            }

            &.mine {
              justify-content: flex-end;

              .message {
                background: @color-personal-blu;
                color: @color-dark-grey;
                margin-left: 20%;

                &.emoji {
                  background: none;
                  padding-bottom: 0 !important;
                  padding-top: 0 !important;
                  box-shadow: none;

                  p {
                    font-size: 2.5rem;
                    margin: 10px 0 15px;
                  }
                }
              }
            }

            &.theirs {
              justify-content: flex-start;

              .message {
                background: #fff;
                float: left;
                margin-right: 20%;
                margin-left: 0;
                max-width: 285px;

                &.emoji {
                  background: none;
                  padding-bottom: 0 !important;
                  padding-top: 0 !important;
                  box-shadow: none;

                  p {
                    font-size: 2.5rem;
                    margin: 10px 0 15px;
                  }
                }
              }
            }
          }
        }

        .chat-input {
          border-top: solid 1px @color-brown-lighter;
          min-height: 45px;

          .reply-to-box {
            display: flex;
            padding: 15px 15px 12px;
            background: #eee8d9;
            border-bottom: solid 1px @color-brown-lighter;
            word-break: break-all;
          }

          .messageInput__highlighter {
            display: none !important;
          }

          input {
            width: 100%;
            border: none;
            margin: 0;
            padding: 1em 1em 0.8em;
            color: @color-rare;
          }

          textarea {
            width: 100%;
            resize: none;
            border: none;
            margin: 0;
            position: relative !important;
            padding: 0.8em 1em 0.6em !important;
          }
        }
      }
    }
  }
}

@screen md {
  .chat {
    .chat-wrapper {
      height: calc(100vh - 16rem);
      height: calc(var(--vh, 1vh) * 100 - 16rem);
    }
  }
}
