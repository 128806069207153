/**
 * @license
 * MyFonts Webfont Build ID 3151022, 2016-01-08T19:49:24-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: CaslonTwoTwentyFour-Bold by ITC
 * URL: http://www.myfonts.com/fonts/itc/caslon-no-224/caslon-two-twe-four-bold/
 * Copyright: Copyright 1990, 2001 Adobe Systems Incorporated. All Rights Reserved.
 *
 * Webfont: NeueHaasUnicaPro-Light by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neue-haas-unica/light/
 * Copyright: Copyright &#x00A9; 2015 Monotype Imaging Inc.  All rights reserved.
 *
 * Webfont: NeueHaasUnicaPro-Medium by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neue-haas-unica/medium/
 * Copyright: Copyright &#x00A9; 2015 Monotype Imaging Inc.  All rights reserved.
 *
 * Webfont: NeueHaasUnicaPro-Regular by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neue-haas-unica/regular/
 * Copyright: Copyright &#x00A9; 2015 Monotype Imaging Inc.  All rights reserved.
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3151022
 * Licensed pageviews: 250,000
 *
 * © 2016 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work
@import url("//hello.myfonts.net/count/3014ae");*/

@font-face {
  font-family: 'CaslonTwoTwentyFour-Bold';
  src: url('../../fonts/3014AE_0_0.eot');
  src: url('../../fonts/3014AE_0_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/3014AE_0_0.woff2') format('woff2'),
    url('../../fonts/3014AE_0_0.woff') format('woff'),
    url('../../fonts/3014AE_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('../../fonts/NeueHaasUnicaPro-Regular.eot');
  src: url('../../fonts/NeueHaasUnicaPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NeueHaasUnicaPro-Regular.woff2') format('woff2'),
    url('../../fonts/NeueHaasUnicaPro-Regular.woff') format('woff'),
    url('../../fonts/NeueHaasUnicaPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('../../fonts/NeueHaasUnicaPro-Italic.eot');
  src: url('../../fonts/NeueHaasUnicaPro-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NeueHaasUnicaPro-Italic.woff2') format('woff2'),
    url('../../fonts/NeueHaasUnicaPro-Italic.woff') format('woff'),
    url('../../fonts/NeueHaasUnicaPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('../../fonts/NeueHaasUnicaPro-Light.eot');
  src: url('../../fonts/NeueHaasUnicaPro-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NeueHaasUnicaPro-Light.woff2') format('woff2'),
    url('../../fonts/NeueHaasUnicaPro-Light.woff') format('woff'),
    url('../../fonts/NeueHaasUnicaPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('../../fonts/NeueHaasUnicaPro-LightIt.eot');
  src: url('../../fonts/NeueHaasUnicaPro-LightIt.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NeueHaasUnicaPro-LightIt.woff2') format('woff2'),
    url('../../fonts/NeueHaasUnicaPro-LightIt.woff') format('woff'),
    url('../../fonts/NeueHaasUnicaPro-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('../../fonts/NeueHaasUnicaPro-Medium.eot');
  src: url('../../fonts/NeueHaasUnicaPro-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NeueHaasUnicaPro-Medium.woff2') format('woff2'),
    url('../../fonts/NeueHaasUnicaPro-Medium.woff') format('woff'),
    url('../../fonts/NeueHaasUnicaPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('../../fonts/NeueHaasUnicaPro-MediumIt.eot');
  src: url('../../fonts/NeueHaasUnicaPro-MediumIt.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NeueHaasUnicaPro-MediumIt.woff2') format('woff2'),
    url('../../fonts/NeueHaasUnicaPro-MediumIt.woff') format('woff'),
    url('../../fonts/NeueHaasUnicaPro-MediumIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('../../fonts/NeueHaasUnicaPro-Bold.eot');
  src: url('../../fonts/NeueHaasUnicaPro-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NeueHaasUnicaPro-Bold.woff2') format('woff2'),
    url('../../fonts/NeueHaasUnicaPro-Bold.woff') format('woff'),
    url('../../fonts/NeueHaasUnicaPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('../../fonts/NeueHaasUnicaPro-BoldItalic.eot');
  src: url('../../fonts/NeueHaasUnicaPro-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NeueHaasUnicaPro-BoldItalic.woff2') format('woff2'),
    url('../../fonts/NeueHaasUnicaPro-BoldItalic.woff') format('woff'),
    url('../../fonts/NeueHaasUnicaPro-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('../../fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsMedium';
  src: url('../../fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSans';
  src: url('../../fonts/NotoSans-BoldItalic.eot');
  src: url('../../fonts/NotoSans-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-BoldItalic.woff2') format('woff2'),
    url('../../fonts/NotoSans-BoldItalic.woff') format('woff'),
    url('../../fonts/NotoSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../../fonts/NotoSans-Regular.eot');
  src: url('../../fonts/NotoSans-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-Regular.woff2') format('woff2'),
    url('../../fonts/NotoSans-Regular.woff') format('woff'),
    url('../../fonts/NotoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../../fonts/NotoSans-Italic.eot');
  src: url('../../fonts/NotoSans-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-Italic.woff2') format('woff2'),
    url('../../fonts/NotoSans-Italic.woff') format('woff'),
    url('../../fonts/NotoSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../../fonts/NotoSans-Medium.eot');
  src: url('../../fonts/NotoSans-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-Medium.woff2') format('woff2'),
    url('../../fonts/NotoSans-Medium.woff') format('woff'),
    url('../../fonts/NotoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../../fonts/NotoSans-Light.eot');
  src: url('../../fonts/NotoSans-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-Light.woff2') format('woff2'),
    url('../../fonts/NotoSans-Light.woff') format('woff'),
    url('../../fonts/NotoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../../fonts/NotoSans-Bold.eot');
  src: url('../../fonts/NotoSans-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-Bold.woff2') format('woff2'),
    url('../../fonts/NotoSans-Bold.woff') format('woff'),
    url('../../fonts/NotoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSerif';
  src: url('../../fonts/NotoSerif-Light.eot');
  src: url('../../fonts/NotoSerif-Light.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NotoSerif-Light.woff2') format('woff2'),
      url('../../fonts/NotoSerif-Light.woff') format('woff'),
      url('../../fonts/NotoSerif-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSerif';
  src: url('../../fonts/NotoSerif-Bold.eot');
  src: url('../../fonts/NotoSerif-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NotoSerif-Bold.woff2') format('woff2'),
      url('../../fonts/NotoSerif-Bold.woff') format('woff'),
      url('../../fonts/NotoSerif-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSerif';
  src: url('../../fonts/NotoSerif-Medium.eot');
  src: url('../../fonts/NotoSerif-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NotoSerif-Medium.woff2') format('woff2'),
      url('../../fonts/NotoSerif-Medium.woff') format('woff'),
      url('../../fonts/NotoSerif-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSerif';
  src: url('../../fonts/NotoSerif-BoldItalic.eot');
  src: url('../../fonts/NotoSerif-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NotoSerif-BoldItalic.woff2') format('woff2'),
      url('../../fonts/NotoSerif-BoldItalic.woff') format('woff'),
      url('../../fonts/NotoSerif-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSerif';
  src: url('../../fonts/NotoSerif-Regular.eot');
  src: url('../../fonts/NotoSerif-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NotoSerif-Regular.woff2') format('woff2'),
      url('../../fonts/NotoSerif-Regular.woff') format('woff'),
      url('../../fonts/NotoSerif-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSerif';
  src: url('../../fonts/NotoSerif-Italic.eot');
  src: url('../../fonts/NotoSerif-Italic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NotoSerif-Italic.woff2') format('woff2'),
      url('../../fonts/NotoSerif-Italic.woff') format('woff'),
      url('../../fonts/NotoSerif-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}


