.waterfall {
  height: 3rem;
  overflow: hidden;
  
  ul {
    animation: scrollUp 25s infinite;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 3rem;
      list-style: none; 
    }
  }
}

$item-count: 10;

@keyframes scrollUp {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 12.5) - 10%}, #{$i * 12.5%} {
      transform: translateY(calc((-100% / $item-count)) * $i);
    }
  }
}