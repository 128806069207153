.main-container {
  .action {
    label {
      cursor: pointer;
    }

    .select-tools {
      // display: none;
      list-style: none;
      background: @color-dark-grey;
      border-radius: 4px;
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s;

      li {
        display: inline-block;
        color: white;
        padding: 8px 0 5px;
        width: 114px;
        text-align: center;
        cursor: pointer;
      }

      .arrow {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 25px;
        left: 50%;
        margin-left: -5px;
        background: @color-dark-grey;
        transform: rotate(45deg);
      }
    }

    .options-wrapper {
      margin-top: 1.5rem;

      .mui {
        margin: 1em 0 0.5em;
        font-weight: bold;
      }

      .option {
        padding: 15px 15px 13px;
        margin-bottom: 1em;
        background: #f9f9f9;
        border-left: solid 5px darken(@color-primary-red, 20%);
        position: relative;
        border-radius: 3px;

        .option-number {
          font-size: 13px;
          text-transform: uppercase;
          margin-bottom: 1em;
          font-weight: bold;
        }

        .close {
          cursor: pointer;
        }

        .move-up {
          cursor: pointer;
          margin-right: 1rem;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .action-container {
      background: white;
      box-shadow: 0 2px 3px rgba(0, 0 0, 0.1);

      iframe {
        border: none;
      }

      &.fade-out {
        animation: fade-out 0.15s;
      }

      &.fade-in {
        .animation(fade-in 0.7s);
      }

      .action-header {
        display: flex;
        border-bottom: 1px solid #ddd;
        align-items: center;
        height: 3em;

        .action-close {
          padding: 1rem;
          cursor: pointer;
          width: 100px;
        }

        .action-type {
          font-family: 'NotoSerif', 'CaslonTwoTwentyFour-Bold', Georgia, Times, 'Times New Roman', serif;
          flex: 1;
          text-align: center;
          font-size: 1.2rem;
        }

        .action-complete {
          width: 100px;
          padding: 1rem;
          padding-left: 0;
          display: flex;
          justify-content: flex-end;

          .icon-complete {
            font-size: 1.2rem;
            display: none;

            &.completed { display: block; }
            &::before { content: "\e964"; }
          }
        }
      }

      .action-close-fake {
        padding: 0.85rem 1rem 1rem;
        cursor: pointer;
        width: 60px;
        position: absolute;
        left: 0;
        top: 0;
      }

      .action-content {
        padding: 3rem 0;
        width: 70%;
        margin: 0 auto;
        position: relative;

        textarea {
          width: 100%;
          height: 200px;
          border: 1px solid @color-brown-light;
          padding: 15px;
          .border-radius(4px);
          .transition(color 0.2s background 0.2s);

          &.noborder {
            height: initial;
          }

          &.placeholder {
            color: #aaa;

            &:active,
            &:focus {
              color: @color-medium-well;
            }
          }

          &.disabled {
            background: #fafafa;
            color: #888;
          }
        }

        .action-title {
          color: @color-well;
          font-weight: bold;
          // margin-bottom: 1.2rem;
          font-size: 1.1rem;
          word-break: break-word;

          &.certificate {
            text-align: center;
          }

          &:disabled {
            color: #bdbdbd;
            border-color: lighten(@color-team-yellow, 20%);
          }
        }

        // .action-photo {
        //   margin: 0 auto 2em;
        //   width: 250px;
        //   height: 250px;
        //   background-repeat: no-repeat;
        //   background-position: 50%;
        //   background-size: cover;
        //   cursor: zoom-in;
        //   .transition(.3s ease);

        //   &.large {
        //     cursor: zoom-out;
        //     width: 100%;
        //     height: 400px;
        //   }

        //   &.editable {
        //     position: relative;
        //     border: solid 1px @color-team-yellow;
        //     cursor: pointer;
        //   }

        //   .trash {
        //     position: absolute;
        //     right: 15px;
        //     bottom: 15px;
        //     width: 40px;
        //     cursor: pointer;
        //   }
        // }

        .action-image-holder {
          width: 250px;
          margin: 0 auto 30px;
          position: relative;
          text-align: center;

          &.large {
            width: auto;
          }
        }

        .action-image {
          object-fit: cover;
          width: 250px;
          height: 250px;
          max-width: 100%;
          border-radius: 2px;
          border: solid 1px white;
          background-color: white;
          cursor: zoom-in;
          // .transition(.3s ease);

          &.editable {
            cursor: pointer;
            border: solid 1px @color-team-yellow;
            padding: 0;
            width: 250px;

            &:disabled {
              color: #bdbdbd;
              border-color: lighten(@color-team-yellow, 20%);
            }
          }

          &.large {
            cursor: zoom-out;
          }
        }

        .trash {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
          width: 40px;
          cursor: pointer;
        }

        .action-description {
          line-height: 1.7rem;
          word-wrap: break-word;

          .augmented {
            color: @color-audio;
            font-weight: bold;
          }
        }

        .action-specific {
          margin-top: 1.2rem;

          &.multiple_choice {
            text-align: center;
            margin-top: 30px;
            user-select: none;
            -khtml-user-select: none;
            -o-user-select: none;
            -moz-user-select: -moz-none;
            -webkit-user-select: none;

            .btn {
              width: 100%;
              margin-bottom: 1rem;
              padding: 1rem 1.5rem;
              border-width: 1px;
              border-color: @color-multiple_choice;
              color: @color-multiple_choice;
              background-color: transparent;
              font-weight: bold;
              border-radius: 0;
              position: relative; // Icons absolute positioning fix
              white-space: normal;
              .transition(color 0.3s, background-color 0.3s);

              &:hover {
                background-color: lighten(@color-multiple_choice, 65%);
                //color: white;
              }

              &.selected {
                background-color: @color-multiple_choice;
                color: white;
              }

              &.selected:hover { background-color: lighten(@color-multiple_choice, 10%); }

              &.answered-poll {
                position: relative;
                color: darken(@color-multiple_choice, 10%);
                background: transparent;

                &:hover {
                  background: none;
                }

                .poll-percentage {
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  background: #d8d8d8;
                }

                &.selected .poll-percentage {
                  background: @color-personal-blu;
                }

                span,
                bdi {
                  position: relative;
                  z-index: 20;
                }
              }

              &.correct,
              &.correct:hover {
                background-color: @color-correct;
                color: white;
                border-color: @color-correct;

                span,
                bdi { visibility: hidden }

                &::after {
                  content: "\e958";
                  position: absolute;
                  top: 15px;
                  left: 48%;
                  font-size: 22px;
                }
              }

              &.wrong,
              &.wrong:hover {
                background-color: @color-wrong;
                color: white;
                border-color: @color-wrong;

                span,
                bdi { visibility: hidden }

                &::after {
                  content: "\e90e";
                  position: absolute;
                  top: 13px;
                  left: 48%;
                  font-size: 22px;
                }
              }
            }

            .action-modal-feedback {
              display: none;
              position: fixed;
              bottom: 0; // action-modal bottom padding
              left: 20%;
              right: 20%;
              //width: 100%;
              padding: 0 0 40px;
              text-align: center;
              background: white;
              z-index: 9999;
              border-style: solid;
              border-width: 0 1px;

              .action-modal-fb-text {
                margin-top: 40px;
                padding-left: 40px;
                padding-right: 40px;
              }

              // .translateY(200);
              // .transition(transform 1s);

              &.show {
                display: block;
                // .translateY(0);
              }

              &.wrong {
                border-color: @color-wrong;
                color: darken(@color-wrong, 10%);
                background-color: lighten(@color-wrong, 47%);

                .action-modal-feedback-loader {
                  background: @color-wrong;
                }
              }

              &.correct {
                border-color: @color-correct;
                color: darken(@color-correct, 10%);
                background-color: lighten(@color-correct, 50%);

                .action-modal-feedback-loader {
                  background: @color-correct;
                }
              }

              .action-modal-feedback-loader {
                width: 100%;
                height: 5px;
              }
            }
          }

          &.assessment {
            text-align: center;
            margin-top: 30px;

            .btn {
              width: 100%;
              margin-bottom: 1rem;
              padding: 1rem 1.5rem;
              border-width: 1px;
              border-color: @color-assessment;
              color: @color-assessment;
              background-color: transparent;
              font-weight: bold;
              border-radius: 4px;
              position: relative; // Icons absolute positioning fix
              white-space: normal;
              .transition(color 0.3s, background-color 0.3s);

              &:hover {
                background-color: lighten(@color-assessment, 25%);
                //color: white;
              }

              &.selected {
                background-color: @color-assessment;
                color: white;
              }

              &.selected:hover { background-color: lighten(@color-assessment, 10%); }

              &.correct,
              &.correct:hover {
                background-color: @color-correct;
                color: white;
                border-color: @color-correct;
                span { visibility: hidden }
              }
            }

            .locked {
              .btn {
                &::before {
                  font-family: 'icomoon', sans-serif;
                  content: '\e92e';
                  margin-right: 5px;
                }
              }
            }
          }

          &.quote {
            text-align: center;
            color: white;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

            .quote-container {
              position: relative;
              height: 525px;
              background-repeat: no-repeat;
              background-position: 50%;
              background-size: cover;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .quote-text {
              margin: 0.5em 1em 0;
              padding-bottom: 0.3em;
              line-height: 1.3em;
              .font-l1();
              .type-headings();

              &::before,
              &::after {
                color: @color-light-yellow;
                content: "\e924";
                font-size: 20px;
              }

              &::before {
                position: relative;
                top: -5px;
                right: 5px;
              }

              &::after {
                margin-left: 5px;
                position: absolute;
                .rotate(180deg);
              }
            }

            .quote-text.editmode {
              position: relative;

              &::before {
                position: absolute;
                top: 0;
                left: -25px;
                right: auto;
              }

              &::after {
                right: -25px;
                bottom: 20px;
                position: absolute;
                .rotate(180deg);
              }
            }

            .quote-author {
              text-transform: uppercase;
              //.type-regular();
              .font-s3();
            }

            .quote-field {
              font-size: 2rem;
              background-color: transparent;
              padding: 0.5rem 1rem 0.3rem;
              color: white;
              display: inline-block;
              width: 93%;
              margin-bottom: 0;
              text-align: center;
              resize: none;
            }

            .author-field {
              font-size: 0.9rem;
              background: transparent;
              color: white;
              display: inline-block;
              width: auto;
              margin-left: 1em;
              text-transform: uppercase;
            }
          }
        }

        .action-modal-buttons {
          margin-top: 2rem;
          position: relative;

          .back {
            margin-right: 10px;
            width: 25px;
            height: 25px;
            transform: rotate(180deg);
            font-size: 11px;
            border-color: silver;
            display: flex;
            align-items: center;
            justify-content: center;
            .border-radius(50%);

            &::after {
              content: "\e96a";
              color: white;
              margin-top: -1px;
            }
          }

          // Chekmark button
          .checkmark {
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 50px;
            font-size: 20px;
            position: relative; // absolute position fix
            transform: rotate(0) /* rtl: rotate(180deg) */;
            .border-radius(50%);

            &:active,
            &:focus {
              outline: none;
            }

            // Translate transform FIX & other
            &::after,
            &::before {
              display: inline-block;
              .transition(transform 0.3s ease-in);
            }

            &.disabled {
              background: white;
              border: solid 1px @color-brown-light;
              color: @color-brown-light;
              overflow: hidden;

              &::after {
                content: "\e96a";
              }
            }

            &.enabled {
              border: none;
              color: white;
              &::after { content: "\e96a"; }
              .transition(background-color 0.5s);

              &.qa { background-color: @color-qa }
              &.text { background-color: @color-text }
              &.photo { background-color: @color-photo }
              &.multiple_choice { background-color: @color-multiple_choice }
              &.assessment { background-color: @color-assessment }
              &.rating { background-color: @color-rating }
              &.link { background-color: @color-link }
              &.external { background-color: @color-external }
              &.image { background-color: @color-image }
              &.video { background-color: @color-video }
              &.audio { background-color: @color-audio }
              &.upload { background-color: @color-upload }
              &.certificate { background-color: @color-certificate }
              &.discussion { background-color: @color-discussion }
              &.notes { background-color: @color-note }
              &.quote { background-color: @color-brown-light; }
              &.word_puzzle { background-color: @color-word_puzzle; }

              &:hover {
                &.qa { background-color: darken(@color-qa, 10%) }
                &.text { background-color: darken(@color-text, 10%) }
                &.photo { background-color: darken(@color-photo, 10%) }
                &.multiple_choice { background-color: darken(@color-multiple_choice, 10%) }
                &.assessment { background-color: darken(@color-assessment, 10%) }
                &.rating { background-color: darken(@color-rating, 10%) }
                &.link { background-color: darken(@color-link, 10%) }
                &.external { background-color: darken(@color-external, 10%) }
                &.image { background-color: darken(@color-image, 10%) }
                &.video { background-color: darken(@color-video, 10%) }
                &.audio { background-color: darken(@color-audio, 10%) }
                &.upload { background-color: darken(@color-upload, 10%) }
                &.certificate { background-color: darken(@color-certificate, 10%) }
                &.discussion { background-color: darken(@color-discussion, 10%) }
                &.notes { background-color: darken(@color-note, 10%) }
                &.quote { background-color: darken(@color-brown-light, 10%); }
                &.word_puzzle { background-color: darken(@color-word_puzzle, 10%); }
              }
            }

            &.editing {
              &::before {
                content: "\e958";
              }

              &::after {
                content: "";
              }
            }
          }

          .remind-me {
            font-size: 14px;
            text-align: center;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .selector {
              display: none;
              padding-top: 10px;

              .btn {
                border-radius: 50%;
                height: 25px;
                width: 25px;
                border-width: 1px;
                overflow: hidden;
                padding: 3px;
                margin-left: 5px;
                font-size: 12px;
                background: @color-primary-red;
                border-color: @color-primary-red
              }

              .form-group {
                margin: 0;
                display: inline;
                &:last-of-type { margin-right: 5px }
              }
            }

            .clock {
              color: @color-brown;
              border: solid 1px @color-brown;
              border-radius: 100%;
              width: 50px;
              height: 50px;
              .transition(color, 0.5s);

              &::after {
                text-align: center;
                content: "\e963";
                display: block;
                font-size: 20px;
              }

              &:hover,
              &:active,
              &:focus {
                background: none;
                color: @color-primary-red;
                outline: none;
              }

              &.reminder-active {
                color: lighten(@color-primary-red, 5%);
                &:hover { color: @color-primary-red; }
              }
            }

            /*
            In case we cannot change reminder:
            &.active .clock { color: @color-primary-red; cursor: @cursor-disabled; }
            */
            &.open {
              width: auto;
              .selector { display: block }
              .clock { display: none }
            }

            &.invisible {
              button,
              .selector { display: none }
            }
          }

          .share-button {
            display: none;

            &.visible {
              display: block;
            }
          }

          .share-status {
            color: @color-dark-grey;
            margin-bottom: 0.5rem;
            font-size: 0.9em;

            &.shared {
              color: @color-team-yellow;
            }
          }

          .reminder-status {
            color: @color-brown;
            margin-bottom: 0.5rem;
            font-size: 0.9em;

            &.shared {
              color: @color-qa;
            }
          }

          .btn.share {
            color: @color-dark-grey;
            border-color: @color-dark-grey;
            background: none;
            height: 50px;
            width: 50px;
            border-width: 1px;
            border-radius: 25px;
            text-align: center;
            padding-top: 10px;
            .transition(color 0.3s, background-color 0.3s);

            &:active,
            &:focus {
              outline: none;
            }

            &::before {
              content: "\e903";
              font-size: 16px;
            } // Team icon

            &:hover { background-color: lighten(@color-team-yellow, 35%) }

            &.shared {
              border-color: @color-team-yellow;
              background-color: @color-team-yellow;
              color: white;
            }

            &.shared:hover { background-color: darken(@color-team-yellow, 10%); }
          }

          .dropdown-menu {
            padding: 0;

            ul {
              margin-bottom: 0;

              li {
                border-bottom: solid 1px @color-brown-lighter;

                a {
                  color: @color-dark-grey;
                  padding: 12px 12px 9px;
                  display: block;

                  &:hover {
                    background: #fdf9ef;
                  }
                }

                &:last-child {
                  border-bottom: 0;
                }

                &.remove {
                  a {
                    color: @color-primary-red;

                    &:hover {
                      background: @color-primary-red;
                      color: white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.optionSortable {
  &.option {
    padding: 1rem;
    margin-bottom: 1em;
    background: #f9f9f9;
    border-left: solid 5px @color-wrong;
    position: relative;
    border-radius: 3px;

    .option-number {
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 1em;
      font-weight: bold;
    }

    .close {
      cursor: pointer;
    }

    .move-up {
      cursor: pointer;
      margin-right: 1rem;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .toggle {
    input {
      max-height: 0;
      max-width: 0;
      opacity: 0;

      & + label {
        display: inline-block;
        position: relative;
        box-shadow: inset 0 0 0 1px #d5d5d5;
        text-indent: -5000px;
        height: 30px;
        width: 50px;
        border-radius: 15px;
        margin: 0;
        cursor: pointer;
      }

      & + label::before {
        content: "";
        position: absolute;
        display: block;
        height: 30px;
        width: 30px;
        top: 0;
        left: 0;
        border-radius: 15px;
        background: rgba(19, 191, 17, 0);
        .transition(0.25s ease-in-out);
      }

      & + label::after {
        content: "";
        position: absolute;
        display: block;
        height: 30px;
        width: 30px;
        top: 0;
        left: 0;
        border-radius: 15px;
        background: white;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
        .transition(0.25s ease-in-out);
      }

      &:checked + label::before {
        width: 50px;
        background: @color-light-green;
      }

      &:checked + label::after {
        left: 20px;
        box-shadow: inset 0 0 0 1px @color-light-green, 0 2px 4px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.dragOptionHandle {
  margin-right: 10px;
  color: @color-team-yellow;
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  margin-top: -1px;
  font-size: 1.5rem;
}
