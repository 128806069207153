//Fade-in animation
.keyframes(~'fade-in, from { opacity: 0; } to { opacity: 1; }');

//Journey chat animation
.keyframes(~'chat-in, from { opacity: 0; transform: translateX(20px) } to { opacity: 1; transform: translateX(0) }');

//Reward Zoom out
.keyframes(~'zoom-out, from { opacity: 0; transform: scale(5) } to { opacity: 1; transform: scale(1) }');

// Glowing text
.keyframes(~'text-glow, 0% { text-shadow: 0 0 10px rgba(255,255,255,0), 0 0 10px rgba(255,255,255,0) } 25% { text-shadow: 0 0 10px rgba(255,255,255,1), 0 0 10px rgba(255,255,255,1)} 100% { text-shadow: 0 0 10px rgba(255,255,255,0), 0 0 10px rgba(255,255,255,0) }');

// Rotate in - button
.keyframes(~'rotate-in, from { opacity: 0; transform: scale(0) rotate(360deg); } to { opacity: 1; transform: scale(1) rotate(0deg); }');

// Blink
.keyframes(~'blink, 0% { opacity: 0; } 25% { opacity: 1; } 100% { opacity: 0 }');

//Pop-in animation
.keyframes(~'pop-in, 0% { opacity: 0; transform: scale(0); } 50% { opacity: 1; transform: scale(1.1); } 100% { transform: scale(1); opacity: 0.8; }');

//Steam fade
.keyframes(~'steam-fade, from { opacity: 0; } to { opacity: 0.3; }');

//Fade-out animation
.keyframes(~'fade-out, from { opacity: 1; } to { opacity: 0; }');

//Logo-in animation
.keyframes(~'logo-in, 0% { opacity: 0; transform: scale(0); } 50% { opacity: 1; transform: scale(1); } 100% { opacity: 0.5; }');

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.uil-ring-css {
  // background: none;
  // position: relative;
  // width: 200px;
  // height: 200px;
}

.uil-ring-css > div {
  position: absolute;
  display: block;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 50%;
  margin-left: -80px;
  border-radius: 80px;
  box-shadow: 0 6px 0 0 #fff;
  -ms-animation: uil-ring-anim 1s linear infinite;
  -moz-animation: uil-ring-anim 1s linear infinite;
  -webkit-animation: uil-ring-anim 1s linear infinite;
  -o-animation: uil-ring-anim 1s linear infinite;
  animation: uil-ring-anim 1s linear infinite;
}

// slide

.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}

.slide-exit {
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}

// fade

.fade-enter {
  animation-name: fade-in;
  animation-duration: 400ms;
}

.fade-enter-active {
  animation-name: fade-in;
  animation-duration: 400ms;
}

.fade-exit {
  animation-name: fade-out;
  animation-duration: 400ms;
}

.fade-exit-active {
  animation-name: fade-out;
  animation-duration: 400ms;
}
