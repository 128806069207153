.notes-container {
  padding: 15px 15px 5px;
  border: solid 1px silver;
  border-radius: 4px;
  white-space: pre-wrap;
  overflow: auto;

  .noborder {
    border: none !important;
    padding: 0 !important;
    min-height: 36px;
    resize: none;
  }

  .snippet {
    position: relative;
    padding: 15px;
    padding-right: 40px;
    line-height: 18px;

    .content {
      line-height: 24px;
    }

    &.qa {
      background: #fef7f5;
      border-left: solid 4px @color-qa;

      .title {
        color: @color-qa;
      }
    }

    &.text {
      background: lighten(@color-text, 52%);
      border-left: solid 4px @color-text;

      .title {
        color: @color-text;
      }
    }

    &.photo {
      background: #f5f0f0;
      border-left: solid 4px @color-photo;

      .title {
        color: @color-photo;
      }
    }

    &.multiple_choice {
      background: #eef4f5;
      border-left: solid 4px @color-multiple_choice;

      .title {
        color: @color-multiple_choice;
      }
    }

    &.assessment {
      background: lighten(@color-assessment, 52%);
      border-left: solid 4px @color-assessment;

      .title {
        color: @color-assessment;
      }
    }

    &.image {
      background: lighten(@color-image, 52%);
      border-left: solid 4px @color-image;

      .title {
        color: @color-image;
      }
    }

    &.chapter {
      background: @color-raw;
      border-left: solid 4px @color-dark-grey;
    }
  }

  .meta {
    cursor: pointer;
    font-size: 12px;
    margin-top: 10px;
  }

  .icon-trash {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
