#alerts {
  position: fixed;
  z-index: 1060;

  .alert {
    position: fixed;
    bottom: 0;
    left: 20%;
    right: 20%;
    padding-top: 5px;
    padding-bottom: 0;
    border: none;
    color: #444;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    transform: translateY(5rem);
    transition: transform 0.4s;
    border-radius: 0.5rem;
    font-weight: bold;

    .close { display: none; }
    &.slide-up { transform: translateY(-1rem); }
    &.alert-success { background-color: #deeff7; }
    &.alert-danger { background-color: #f9e0ea; }
  }
}
