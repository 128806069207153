.archive {
  h3 {
    margin-bottom: 0.8rem;

    .organization {
      margin-top: 0.65rem;
      font-size: 0.8rem;
      text-transform: uppercase;
      color: @color-brown;
      letter-spacing: 1.5px;
      font-weight: medium;
      font-family: 'NotoSans', 'NeueHaasUnicaPro', "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }

  ul.archive-list {
    overflow: auto;

    li {
      width: 23%;
      height: 280px;
      line-height: 20px;
      overflow: hidden;
      font-size: 0.9rem;

      .course-cover {
        background-position: center;
        background-size: cover;
        width: 100%;
        padding-top: 100%;
        cursor: pointer;

        &.empty {
          border: solid 1px #7a7a7a;
        }
      }

      .course-title {
        color: @color-full;
        font-weight: bold;
        line-height: 1.4rem;
      }

      .btn.btn-primary {
        margin-top: 0.5rem;
        margin-bottom: 0.7rem;
        width: 100%;
      }

      // .course-title {
      //   color: white;
      // }

      // .course-subtitle {
      //   color: #9B9B9B;
      // }

      &.invisible {
        width: 22%; /* the width of the images in this example */
        height: 0; /* Important! for the divs to collapse should they fall in a new row */
      }
    }
  }
}
