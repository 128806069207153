@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?2lhq0s');
  src: url('../../fonts/icomoon.eot?2lhq0s#iefix') format('embedded-opentype'),
       url('../../fonts/icomoon.ttf?2lhq0s') format('truetype'),
       url('../../fonts/icomoon.woff?2lhq0s') format('woff'),
       url('../../fonts/icomoon.svg?2lhq0s#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-puzzle-piece-light::before {
  display: inline-block;
  transform: rotate(-90deg);
  content: "\e992";
}

.icon-exclamation-triangle-light::before {
  content: "\e991";
}

.icon-arrow-to-bottom-light::before {
  content: "\e990";
}

.icon-eraser-light::before {
  content: "\e98f";
}

.icon-sign-out-light::before {
  content: "\e98e";
}

.icon-share-light::before {
  content: "\e98c";
}

.icon-upload-light::before {
  content: "\e98d";
}

.icon-more-button::before {
  content: "\e98b";
}

.icon-collapse::before {
  content: "\e98a";
}

.icon-inbox-out-light::before {
  content: "\e989";
}

.icon-archive-light::before {
  content: "\e986";
}

.icon-pin-solid::before {
  content: "\e985";
}

.icon-fav-solid::before {
  content: "\e987";
}

.icon-info::before {
  content: "\e988";
}

.icon-attach-solid::before {
  content: "\e983";
}

.icon-attach-light::before {
  content: "\e984";
}

.icon-paper-plane-light-enabled::before {
  content: "\e97c";
}

.icon-paper-plane-light::before {
  content: "\e97d";
}

.icon-reply {
  &::before {
    content: "\e97e";
  }

  &.flipped {
    display: inline-block;
    transform: scaleX(-1);
  }
}

.icon-add-participant::before {
  content: "\e97f";
}

.icon-do_disturb_24px_outlined::before {
  content: "\e980";
}

.icon-external-link-light::before {
  content: "\e981";
}

.icon-ellipsis-v::before {
  content: "\e982";
}

.icon-arrow-left-solid::before {
  content: "\e97b";
}

.icon-search-solid::before {
  content: "\e97a";
}

.icon-chart-line-solid::before {
  content: "\e977";
}

.icon-chart-line-light::before {
  content: "\e978";
}

.icon-ellipsis-h-solid::before {
  content: "\e979";
}

.icon-pencil-light::before {
  content: "\e976";
}

.icon-sort-numeric-up-light::before {
  content: "\e970";
}

.icon-sort-numeric-down-light::before {
  content: "\e971";
}

.icon-sort-alpha-down-light::before {
  content: "\e972";
}

.icon-sort-alpha-up-light::before {
  content: "\e973";
}

.icon-minus-light::before {
  content: "\e974";
}

.icon-home-light::before {
  content: "\e975";
}

.icon-floppy-full::before {
  content: "\e96b";
}

.icon-floppy::before {
  content: "\e96c";
}

.icon-back::before {
  content: "\e96d";
}

.icon-class-full::before {
  content: "\e96e";
}

.icon-class-light::before {
  content: "\e96f";
}

.icon-stopwatch-solid::before {
  content: "\e968";
}

.icon-stopwatch-light::before {
  content: "\e969";
}

.icon-edit-light::before {
  content: "\e966";
}

.icon-volume-mute-light::before {
  content: "\e900";
}

.icon-volume-light::before {
  content: "\e901";
}

.icon-video-light::before {
  content: "\e902";
}

.icon-users-solid::before {
  content: "\e903";
}

.icon-users-light::before {
  content: "\e904";
}

.icon-user-solid::before {
  content: "\e905";
}

.icon-user-light::before {
  content: "\e906";
}

.icon-user-friends-solid::before {
  content: "\e907";
}

.icon-user-friends-light::before {
  content: "\e908";
}

.icon-user-circle-solid::before {
  content: "\e909";
}

.icon-user-circle-light::before {
  content: "\e90a";
}

.icon-university-solid::before {
  content: "\e90b";
}

.icon-university-light::before {
  content: "\e90c";
}

.icon-trash-alt-light::before {
  content: "\e90d";
}

.icon-times-light::before {
  content: "\e90e";
}

.icon-times-circle-light::before {
  content: "\e90f";
}

.icon-sync-light::before {
  content: "\e910";
}

.icon-star-solid::before {
  content: "\e911";
}

.icon-star-light::before {
  content: "\e912";
}

.icon-star-half-alt-solid::before {
  content: "\e913";
}

.icon-spell-check-solid::before {
  content: "\e914";
}

.icon-spell-check-light::before {
  content: "\e915";
}

.icon-sort::before {
  content: "\e916";
}

.icon-shopping-cart-solid::before {
  content: "\e917";
}

.icon-shopping-cart-light::before {
  content: "\e918";
}

.icon-shopping-bag-solid::before {
  content: "\e919";
}

.icon-shopping-bag-light::before {
  content: "\e91a";
}

.icon-share-alt-square-light::before {
  content: "\e91b";
}

.icon-session-solid::before {
  content: "\e91c";
}

.icon-session-light::before {
  content: "\e91d";
}

.icon-session-list::before {
  content: "\e91e";
}

.icon-search-light::before {
  content: "\e91f";
}

.icon-sack-solid::before {
  content: "\e920";
}

.icon-sack-light::before {
  content: "\e921";
}

.icon-redo-light::before {
  content: "\e922";
}

.icon-quote-right-solid::before {
  content: "\e923";
}

.icon-quote-left-solid::before {
  content: "\e924";
}

.icon-question-circle-light::before {
  content: "\e925";
}

.icon-plus-circle-light::before {
  content: "\e926";
}

.icon-play-light::before {
  content: "\e927";
}

.icon-pen-square-solid::before {
  content: "\e928";
}

.icon-pen-square-light::before {
  content: "\e929";
}

.icon-pause-light::before {
  content: "\e92a";
}

.icon-paste-solid::before {
  content: "\e92b";
}

.icon-paste-light::before {
  content: "\e92c";
}

.icon-note-light::before {
  content: "\e92d";
}

.icon-lock-solid::before {
  content: "\e92e";
}

.icon-lock-alt-light::before {
  content: "\e92f";
}

.icon-link-solid::before {
  content: "\e930";
}

.icon-link-light::before {
  content: "\e931";
}

.icon-library-solid::before {
  content: "\e967";
}

.icon-library-light::before {
  content: "\e932";
}

.icon-images-solid::before {
  content: "\e933";
}

.icon-images-light::before {
  content: "\e934";
}

.icon-image-solid::before {
  content: "\e935";
}

.icon-image-light::before {
  content: "\e936";
}

.icon-home-lg-alt-light::before {
  content: "\e937";
}

.icon-heart-solid::before {
  content: "\e938";
}

.icon-heart-light::before {
  content: "\e939";
}

.icon-headphones-solid::before {
  content: "\e93a";
}

.icon-headphones-light::before {
  content: "\e93b";
}

.icon-group-solid::before {
  content: "\e93c";
}

.icon-group-light::before {
  content: "\e93d";
}

.icon-gift-light::before {
  content: "\e93e";
}

.icon-filter-light::before {
  content: "\e93f";
}

.icon-film-alt-solid::before {
  content: "\e940";
}

.icon-film-alt-light::before {
  content: "\e941";
}

.icon-file-edit-light::before {
  content: "\e942";
}

.icon-file-certificate-solid::before {
  content: "\e943";
}

.icon-file-certificate-light::before {
  content: "\e944";
}

.icon-file-alt-solid::before {
  content: "\e945";
}

.icon-file-alt-light::before {
  content: "\e946";
}

.icon-eye-slash-light::before {
  content: "\e947";
}

.icon-eye-light::before {
  content: "\e948";
}

.icon-exclamation-circle-light::before {
  content: "\e949";
}

.icon-envelope-light::before {
  content: "\e94a";
}

.icon-ellipsis-h-light::before {
  content: "\e94b";
}

.icon-ellipsis-h-alt-light::before {
  content: "\e94c";
}

.icon-comment-solid::before {
  content: "\e94d";
}

.icon-comment-light::before {
  content: "\e94e";
}

.icon-comment-alt-solid::before {
  content: "\e950";
}

.icon-comment-alt-light::before {
  content: "\e94f";
}

.icon-cog-light::before {
  content: "\e951";
}

.icon-clock-light::before {
  content: "\e952";
}

.icon-clipboard-list-light::before {
  content: "\e953";
}

.icon-clipboard-light::before {
  content: "\e954";
}

.icon-circle-solid::before {
  content: "\e955";
}

.icon-check-square-solid::before {
  content: "\e956";
}

.icon-check-square-light::before {
  content: "\e957";
}

.icon-check-light::before {
  content: "\e958";
}

.icon-caret-down-light::before {
  content: "\e959";
}

.icon-camera-light::before {
  content: "\e95a";
}

.icon-book-solid::before {
  content: "\e95b";
}

.icon-book-light::before {
  content: "\e95c";
}

.icon-book-open-light::before {
  content: "\e95d";
}

.icon-bell-solid-1::before {
  content: "\e95e";
}

.icon-bell-light::before {
  content: "\e95f";
}

.icon-bars-light::before {
  content: "\e960";
}

.icon-angle-right-light {
  transition: 0.2s;

  &::before {
    content: "\e961";
  }

  &.up {
    transform: rotate(270deg);
    display: inline-block;
  }

  &.down {
    transform: rotate(90deg);
    display: inline-block;
  }

  &.left {
    transform: rotate(180deg);
    display: inline-block;
  }

  &.big {
    font-size: 1rem;
  }
}

.icon-alarm-snooze-solid::before {
  content: "\e962";
}

.icon-alarm-snooze-light::before {
  content: "\e963";
}

.icon-checkmark-crcl::before {
  content: "\e964";
}

.icon-next::before {
  content: "\e96a";
}

.icon-x::before {
  content: "\e965";
}

.icon-programs-outline::before {
  content: "\e932";
}
