.loader:before,
.loader:after,
.loader {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  //margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

// Buttons Loader
.btn {
  &.upload-button { width: 150px; height: 35px; margin-right: 10px; }
  .loader { display: none }
  &.loading {
    // overflow: auto;
    //width: 100%;
    //height: 30px;
    // padding: 0;
    // span { display: none }
    .loader {
      display: block !important;
      margin: -3em auto auto auto;
      font-size: 8px;
      width: 1em;
      height: 1em;
      &:before,
      &:after {
        width: 1em;
        height: 1em;
      }
      &:before { left: -1.5em; }
      &:after { left: 1.5em;
      }
    }
  }
}