@import 'tailwindcss/base.css';
@import 'tailwindcss/components.css';
@import 'tailwindcss/utilities.css';

// Mixins & Utilities
@import 'utils/lesshat.less';
@import 'utils/fontface.less';
@import 'utils/text.less';
@import 'utils/typography.less';
@import 'utils/variables.less';
@import 'utils/icomoon.less';
@import 'utils/loader.less';

// Global namespace
@import 'globals/base.less';
@import 'globals/animations.less';

// Custom
@import 'globals/custom.less';
@import 'globals/rtl.less';
@import 'utils/mixins.less';

// Containers
@import 'containers/login.less';
@import 'containers/course-details.less';
@import 'containers/workbook.less';
@import 'containers/settings.less';

// Components
@import 'components/alert.less';
@import 'components/navbar.less';
@import 'components/course-list.less';
@import 'components/chapter-list.less';
@import 'components/current-session.less';
@import 'components/action.less';
@import 'components/curator.less';
@import 'components/course.less';
@import 'components/action-item.less';
@import 'components/action-modal.less';
@import 'components/chat.less';
@import 'components/search.less';
@import 'components/trix.less';
@import 'components/goodiebag.less';
@import 'components/messaging.less';
@import 'components/archive.less';
@import 'components/assessments.less';
@import 'components/notes.less';
@import 'components/toggle.less';
@import 'components/react-star-rating.min.css';
// @import 'components/react-joyride-compiled.css';
@import 'react-quill/dist/quill.snow.css';

// Filestack modal z-index - so it is over Bootstrap modal
.fp__overlay {
  z-index: 2000 !important;
}

@layer utilities {
  .bottom-unset {
    bottom: unset !important;
  }

  .rounded-xl {
    border-radius: 1rem !important;
  }

  .text-shadow {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }

  .text-shadow-sm {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }

  .appearance-none {
    appearance: none;
    -webkit-appearance: none;
  }

  .tooltip {
    margin-left: -15px;
  }

  .tooltip::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent theme('colors.deepgray') transparent;
    transform: translateX(-50%) rotate(180deg);
  }

  @keyframes pulse {
      0% {
          transform: scale(1);
      }
      50% {
          transform: scale(1.1);
      }
      100% {
          transform: scale(1);
      }
  }

  .icon-animate {
      animation: pulse 1s infinite;
  }
}

/* Target the scrollable div */
.scrollable-div {
  overflow-y: auto; /* Enables vertical scrolling */
}

/* Style the scrollbar */
.scrollable-div::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  border-radius: 12px;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #4A4A4A; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Round edges of the thumb */
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color when hovering */
}

.scrollable-div::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Color of the scrollbar track */
  border-radius: 4px; /* Optional: Round edges of the track */
}
