html[dir=rtl] {
  .navbar {
    .progress-btn {
      padding-right: 18px;

      @apply left-0 right-auto;
    }

    .new-course-btn,
    .gnowbefy-btn {
      .navbar-label {
        margin-right: 10px;
        margin-left: 0;
      }
    }

    .mobile-more-menu {
      @apply left-0 right-auto !important;
    }
  }

  .main-container {
    .chapter-list-container.visible {
      @apply left-0 right-auto !important;
    }

    .course-list-container.visible {
      @apply right-0 left-auto !important;
    }
  }
}
