.main-container {
  .login-box {
    background-color: white;
    margin-top: 3em;
    padding: 3em 3em;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
      width: 100%;
    }

    @media (min-width: 992px) {
      width: 50%;
    }

    label.form-label {
      text-align: right;
      padding-right: 5px;
    }

    .login-bottom {
      padding-top: 20px;
      text-align: center;
    }

    img {
      height: 50px;
      display: inline-block;
    }
  }

  .logo-title {
    min-height: 100%;
    padding: 40px 0 40px;
    margin: 0;
    text-align: center;
    color: @color-medium;
    .font-m1();
  }

  .login-btn {
    display: inline-block;
    padding: 12px 20px 10px;
    color: @color-dark-grey;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.2s;
    margin-top: 1em;

    &.facebook {
      background-color: #48629b;
      color: white;
      font-weight: normal;

      &:hover {
        background-color: #425a93;
      }

      &:active {
        background-color: #394e80;
      }
    }

    &.linkedin {
      background-color: #0e76a9;
      color: white;
      font-weight: normal;

      &:hover {
        background-color: #006999;
      }

      &:active {
        background-color: #005c87;
      }
    }

    &.gnowbe {
      color: white;
      font-weight: normal;
      background: #b50700;
    }

    &.continue-as {
      background-color: #ff2410;
    }
  }

  .continue-as-separator {
    padding: 30px 0;
  }
}
