.action-item {
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  background: white;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .dropdown,
  .reward {
    transition: opacity 0.3s;

    &:active,
    &.open {
      opacity: 1;
    }

    a {
      padding-top: 4px;
    }
  }

  .dropdown-delete {
    color: @color-primary-red;
    transition: none;

    &:hover {
      background: @color-primary-red;
      color: white;
    }

    &:focus,
    &:active {
      background: darken(@color-primary-red, 10%);
      color: white;
    }
  }

  .open {
    .btn-edit {
      background: @color-team-yellow;
      border: solid 1px @color-team-yellow;
    }
  }

  .btn-edit {
    font-family: 'icomoon', sans-serif;
    color: @color-team-yellow;
    z-index: 1;
    font-size: 1.5rem;
    transform: rotate(90deg);
    padding: 0 0.5rem;

    &:hover {
      color: darken(@color-team-yellow, 15%);
    }

    &::before {
      content: '\e982';
      display: inline-block;
    }

    &::after {
      border: none;
    }
  }

  .dragActionHandle {
    font-size: 20px;
    opacity: 1;
    cursor: grab;
    cursor: -webkit-grab;
    cursor: -moz-grab;
  }

  .edit-action {
    .reward { font-size: 24px; }
    .dropdown.show { opacity: 1; }
  }

  &.disabled {
    opacity: 0.4;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 1);
    .scale(0.98);

    .action-type {
      &.color-qa { text-shadow: 0 0 8px fade(@color-qa, 90%); color: fade(@color-qa, 0%); }
      &.color-text { text-shadow: 0 0 8px fade(@color-text, 90%); color: fade(@color-text, 0%); }
      &.color-photo { text-shadow: 0 0 8px fade(@color-photo, 90%); color: fade(@color-photo, 0%); }
      &.color-multiple_choice { text-shadow: 0 0 8px fade(@color-multiple_choice, 90%); color: fade(@color-multiple_choice, 0%) }
      &.color-rating { text-shadow: 0 0 8px fade(@color-rating, 90%); color: fade(@color-rating, 0%); }
      &.color-link { text-shadow: 0 0 8px fade(@color-link, 90%); color: fade(@color-link, 0%); }
      &.color-external { text-shadow: 0 0 8px fade(@color-external, 90%); color: fade(@color-external, 0%); }
      &.color-image { text-shadow: 0 0 8px fade(@color-image, 90%); color: fade(@color-image, 0%); }
      &.color-video { text-shadow: 0 0 8px fade(@color-video, 90%); color: fade(@color-video, 0%); }
      &.color-audio { text-shadow: 0 0 8px fade(@color-audio, 90%); color: fade(@color-audio, 0%); }
      &.color-discussion { text-shadow: 0 0 8px fade(@color-discussion, 90%); color: fade(@color-discussion, 0%); }
      &.color-note { text-shadow: 0 0 8px fade(@color-note, 90%); color: fade(@color-note, 0%); }
      &.color-upload { text-shadow: 0 0 8px fade(@color-upload, 90%); color: fade(@color-upload, 0%); }
    }

    .action-content .action-title {
      text-shadow: 0 0 8px fade(@color-well, 90%);
      color: fade(@color-well, 0%);

      &:disabled {
        color: #bdbdbd;
        border-color: @color-team-yellow;
      }
    }

    &.show-title {
      .action-content .action-title {
        text-shadow: none;
        color: @color-well;
      }
    }

    &::after {
      box-shadow: 0 0 3px 0 @color-brown-lighter;
      background-color: @color-brown-lighter !important;
    }

    &.quote { .blur(4px); }
  }

  &.highlighted {
    border: solid 2px @color-primary-red;
  }

  &.quote {
    background: transparent;
    box-shadow: none;
    text-align: center;
    color: white;

    .quote-text {
      margin-top: 0.5em;
      padding-bottom: 0.3em;
      line-height: 1.3em;
      .font-l1();
      .type-headings();

      &::before,
      &::after {
        color: @color-team-yellow;
        content: "\e924";
        font-size: 20px;
      }

      &::before {
        position: relative;
        right: 10px;
      }

      &::after {
        margin-left: 10px;
        display: inline-block;
        .rotate(180deg);
      }
    }

    .quote-author {
      text-transform: uppercase;
      .font-s3();
    }
  }

  .shadow {
    background-color: @color-medium-well;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: rgba(255, 255, 255, 0.5) 0 3px 3px
  }

  .action-type {
    padding: 0 2em 0 0;
    text-align: center;
    .type-headings();
    .font-m1();

    .icon-complete {
      display: none;
      margin-top: 20px;
      .font-m2();

      &::before { content: "\e964"; }
      &.completed { display: block; }
    }
  }

  .action-content {
    padding: 0 2em 0 0;

    .action-title {
      color: @color-well;
      font-weight: bold;
      word-break: break-word;

      &:disabled {
        color: #bdbdbd;
        border-color: @color-team-yellow;
      }
      // rtl
      // direction: rtl;
    }

    .action-description {
      font-size: 0.9em;
      margin-top: 1.5rem;
      word-wrap: break-word;
      color: @color-medium;

      &.qa { border-color: @color-qa }
      &.text { border-color: @color-text }
      &.photo { border-color: @color-photo }
      &.multiple_choice { border-color: @color-multiple_choice }
      &.rating { border-color: @color-rating }
      &.link { border-color: @color-link }
      &.external { border-color: @color-external }
      &.image { border-color: @color-image }
      &.video { border-color: @color-video }
      &.audio { border-color: @color-audio }
      &.upload { border-color: @color-upload }
      &.discussion { border-color: @color-discussion }
      &.notes { border-color: @color-note }
    }
  }

  .required-time {
    color: @color-brown-light;
    padding-top: 20px;
    text-align: center;
    .type-regular();
    .font-s2();
  }

  .share-meta {
    position: static;
    bottom: 0;
    padding: 0;
    margin-top: 1.5em;

    .action-activity {
      .likes-counter {
        margin-left: 1rem;
        color: @color-brown;
        display: inline;
        margin-right: 4px /* ltr: ignore */;
        .font-s3(); // Predefined font size

        .counter {
          margin-left: 4px /* rtl: ignore */;
        }

        [class^="icon-"] {
          position: relative;
          top: 1px;
        }

        a {
          color: @color-brown;
          &:hover { color: @color-primary-red; }
        }

        &.red {
          color: lighten(@color-primary-red, 5%);
          &:hover { color: @color-primary-red; }
        }

        &:hover {
          color: @color-primary-red;
          text-decoration: none;
        }
      }

      // Comments counter
      .comments-counter {
        color: @color-brown;
        .font-s3(); // Predefined font size
        .counter { margin-left: 4px /* rtl: ignore */; }
        .scaleX(-1);

        &.red { color: @color-primary-red; }

        &:hover {
          color: @color-primary-red;
          text-decoration: none;
        }
      }
    }

    .shared-icon {
      position: absolute;
      bottom: 14px;
      right: 30px;
      color: @color-brown-light;
    }
  }

  // Right line
  &::before {
    width: 4px;
    height: ~"calc(100% - 11px)";
    position: absolute;
    right: 5px;
    top: 6px;
    border-radius: 5px;
    background-color: @color-brown-lighter;
    display: block;
    content: '';

    & .qa { background-color: @color-qa }
    & .text { background-color: @color-text }
    & .photo { background-color: @color-photo }
    & .multiple_choice { background-color: @color-multiple_choice }
    & .rating { background-color: @color-rating }
    & .link { background-color: @color-link }
    & .external { background-color: @color-external }
    & .image { background-color: @color-image }
    & .video { background-color: @color-video }
    & .audio { background-color: @color-audio }
    & .certificate { background-color: @color-certificate }
    & .upload { background-color: @color-upload }
    & .discussion { background-color: @color-discussion }
    & .notes { background-color: @color-note }
  }

  &.quote::before { display: none } // Hide on quotes

  &.completed.qa::before { background-color: @color-qa }
  &.completed.text::before { background-color: @color-text }
  &.completed.photo::before { background-color: @color-photo }
  &.completed.multiple_choice::before { background-color: @color-multiple_choice }
  &.completed.rating::before { background-color: @color-rating }
  &.completed.link::before { background-color: @color-link }
  &.completed.external::before { background-color: @color-external }
  &.completed.image::before { background-color: @color-image }
  &.completed.video::before { background-color: @color-video }
  &.completed.audio::before { background-color: @color-audio }

  &.outlined.qa { box-shadow: 0 0 8px @color-qa }
  &.outlined.text { box-shadow: 0 0 8px @color-text }
  &.outlined.photo { box-shadow: 0 0 8px @color-photo }
  &.outlined.multiple_choice { box-shadow: 0 0 8px @color-multiple_choice }
  &.outlined.rating { box-shadow: 0 0 8px @color-rating }
  &.outlined.link { box-shadow: 0 0 8px @color-link }
  &.outlined.external { box-shadow: 0 0 8px @color-external }
  &.outlined.image { box-shadow: 0 0 8px @color-image }
  &.outlined.video { box-shadow: 0 0 8px @color-video }
  &.outlined.audio { box-shadow: 0 0 8px @color-audio }
  &.outlined.upload { box-shadow: 0 0 8px @color-upload }

  &.completed.discussion::before { background-color: @color-discussion }
  &.completed.notes::before { background-color: @color-note }

  @cert-light: fade(@color-brown-lighter, 50%);

  &.certificate {
    background-color: @color-certificate;
    &::before { background-color: @cert-light; }

    &.completed {
      &.certificate::before { background-color: white }
      .action-type::before { background-color: white }
      .action-content::before { background-color: white }
    }

    .required-time {
      align-self: flex-start;
      padding-left: 0.5rem;
      padding-bottom: 0.2rem;
    }

    .action-title { color: white }
    .action-description.certificate { color: white; }

    .action-type {
      padding-right: 10px;
      position: inherit; // Frame line hack

      .type-name {
        background: url('/app/common/images/graduate.svg');
        display: block;
        max-width: 80px;
        height: 50px;
        background-repeat: no-repeat;
        color: transparent;
      }

      .icon-complete { display: none }

      // Small vertical line trick
      &::after {
        width: 4px;
        height: 34px;
        position: absolute;
        left: 65px;
        background-color: white;
        content: '';
        border-radius: 4px;
      }
    }

    // Bottom line trick
    .action-content {
      position: inherit; // Frame line hack

      &::before {
        height: 4px;
        position: absolute;
        left: 65px;
        right: 5px;
        bottom: 5px;
        background-color: @cert-light;
        border-radius: 4px;
        content: '';
      }
    }

    &.disabled {
      box-shadow: 0 0 10px 5px @color-certificate;
      text-shadow: 0 0 8px fade(white, 90%);
      color: fade(white, 0%);

      .action-content .action-title {
        text-shadow: 0 0 8px fade(white, 90%);
        color: fade(white, 0%);
      }

      &::after,
      .action-type::after,
      .action-content::after {
        box-shadow: 0 0 8px 0 @cert-light;
        background-color: @cert-light !important;
      }

      .type-name {
        font-size: 1px; // Dirty trick to remove shadow from transaprent text
        .blur(3px); // Diploma icon blur
      }

      .redTag { .blur(4px); }
    }
  }
}

.actionSortable {
  .action-item {
    animation: none;

    .dragActionHandle,
    .edit-action {
      opacity: 1;
    }
  }
}

.action-item-v2 {
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2;

  .action-title {
    word-break: break-word;
  }

  .btn-edit {
    font-family: 'icomoon', sans-serif;
    color: @color-team-yellow;
    z-index: 1;
    font-size: 1.5rem;
    transform: rotate(90deg);
    padding: 0 0.5rem;

    &:hover {
      color: darken(@color-team-yellow, 15%);
    }

    &::before {
      content: '\e982';
      display: inline-block;
    }

    &::after {
      display: none !important;
    }
  }

  &.disabled {
    @apply blur-sm opacity-40 select-none;

    transform: scale(0.99) !important;

    div {
      color: rgba(74, 74, 74);
    }
  }
}
