.course-details {
  position: relative;

  ul,
  ol {
    margin: 0 0 0 2em;
  }

  ul { list-style: initial; }
  ol { list-style: decimal; }

  .page {
    padding: 2rem 2rem 1.5rem;
  }

  .page-nopad {
    margin-top: 0;
    padding-top: 0;
    box-shadow: none;
  }

  .course-image-holder {
    width: 250px;
    height: 250px;
    overflow: hidden;
    background: #f9f9f9;

    &.large {
      width: auto;
    }

    .trash {
      position: absolute;
      right: 15px;
      bottom: 15px;
      width: 40px;
      cursor: pointer;
    }
  }

  .course-image {
    width: 250px;
    height: 250px;
    object-fit: cover;
    display: inline-block;
    border-radius: 2px;
    position: relative;

    &.editable {
      width: 250px;
      padding: 0;
    }
  }

  .left-column {
    background: white;
    margin-top: 1em;
    line-height: 1.7em;
    align-self: flex-start;
  }

  .course-stats {
    margin: 25px 0;
    overflow: auto;

    ul,
    ol {
      margin: 0;
      list-style: none;
    }

    li {
      width: 32%;
      float: left;
      font-weight: bold;
      text-align: center;

      .golden-spec {
        color: #a99787;
        text-transform: uppercase;
        font-size: 12px;
        display: block;
      }
    }
  }

  .edit-course-title {
    text-align: center;
    font-size: 1em;
    height: auto;
    padding-bottom: 3px;
    margin-top: -8px;
    margin-bottom: 6px;
  }

  .course-tagline {
    text-align: center;
    font-size: 1.5em;
    font-family: 'NotoSerif', 'CaslonTwoTwentyFour-Bold', serif;
    color: @color-brown;
    line-height: 1.5em;

    em {
      font-family: 'NotoSerif', 'CaslonTwoTwentyFour-Bold', serif;
    }
  }

  h3 { margin: 1em 0; }

  .editable-v2 {
    border: solid 1px transparent;
    border-radius: 4px;
    transition: border-color 0.1s;

    &:hover,
    &:focus {
      cursor: text;
      border: solid 1px @color-team-yellow;
      outline: none;

      &.photo {
        cursor: pointer;
      }
    }

    &:disabled {
      color: #bdbdbd;
      border-color: lighten(@color-team-yellow, 20%);
    }
  }

  .curator-intro {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .curator-photo-holder {
      position: relative;
      width: 60px;
      height: 60px;
      overflow: hidden;
      float: left;
      margin-right: 1em;
      border-radius: 50%;
      background: #f9f9f9;

      .trash {
        position: absolute;
        right: -5px;
        bottom: -2px;
        width: 25px;
        cursor: pointer;
      }
    }

    .curator-photo {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 100%;

      &.editable {
        cursor: pointer;
        padding: 0;
        border: solid 1px @color-team-yellow;
      }
    }

    .curator-name { line-height: 1.4em; }
  }

  .session-list {
    margin: 0;
    list-style: decimal;
    list-style-position: inside;
    font-size: 0.9em;

    li {
      padding: 0.8em 0 0.7em;

      a {
        color: #777;

        &:hover {
          color: black;
        }
      }
    }

    li:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: lighten(@color-personal-blu, 30%);
    }

    &::-webkit-scrollbar-thumb {
      background: @color-personal-blu;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken(@color-personal-blu, 20%);
    }
  }

  .button-switch-wrapper {
    padding-top: 2em;
    margin-top: 2em;
    border-top: solid 1px #ddd;
    text-align: center;
  }

  .sidebar {
    .nav-tabs {
      margin: 0;
    }

    h4 {
      color: white;
      margin-top: 1.4em;
      margin-bottom: 0.4em;
      padding-bottom: 0;
      font-size: 1.4em;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    }

    .switch {
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 1em;
    }
  }

  .outlined {
    border: solid 2px @color-primary-red;
  }
}

.new-session-list {
  margin: 0;
  list-style: decimal;
  list-style-position: inside;
  font-size: 0.9em;

  li {
    padding: 0.8em 0 0.7em;

    a {
      color: #777;

      &:hover {
        color: black;
      }
    }
  }

  li:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: lighten(@color-personal-blu, 30%);
  }

  &::-webkit-scrollbar-thumb {
    background: @color-personal-blu;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken(@color-personal-blu, 20%);
  }
}