.action-modal {
  .editable {
    line-height: 1.7rem;

    &:disabled {
      color: #bdbdbd;
      border-color: lighten(@color-team-yellow, 20%);
    }
  }

  .action-modal-description {
    line-height: 1.7rem;

    p {
      line-height: 1.7rem;
    }
  }

  .action-modal-feedback {
    position: absolute;
    bottom: -30px; // action-modal bottom padding
    left: 0;
    right: 0;
    //width: 100%;
    padding: 30px 30px 50px;
    text-align: center;
    background: white;
    z-index: 9999;
    border-style: solid;
    border-width: 0 1px;

    .translateY(200);
    .transition(transform 1s);

    &.show {
      .translateY(0);
    }

    &.wrong {
      border-color: @color-wrong;
      color: darken(@color-wrong, 10%);
      background-color: lighten(@color-wrong, 50%);

      .action-modal-feedback-loader {
        height: 5px;

        div {
          width: 100%;
          background: @color-wrong;
        }
      }
    }

    &.correct {
      border-color: @color-correct;
      color: darken(@color-correct, 10%);
      background-color: lighten(@color-correct, 50%);

      .action-modal-feedback-loader {
        height: 5px;

        div {
          width: 100%;
          background: @color-correct;
        }
      }
    }
  }

  &.action-edit-modal {
    .edit-minutes {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      font-size: 1rem;

      input {
        width: 45px;
        text-align: center;
        margin-right: 10px;
      }
    }

    .action-photo {
      position: relative;
      border: solid 1px @color-team-yellow;
      background-color: white;

      .trash {
        position: absolute;
        right: 15px;
        bottom: 15px;
        width: 40px;
        cursor: pointer;
      }
    }

    .options-wrapper {
      .option {
        padding: 1em 1em 0.5em 1em;
        margin-bottom: 1em;
        background: #f4f4f4;
        position: relative;

        .option-number {
          font-size: 13px;
          text-transform: uppercase;
          margin-bottom: 1em;
          font-weight: bold;
        }

        .close {
          position: absolute;
          right: 1em;
          top: 1em;
          cursor: pointer;
        }
      }
    }

    .upload {
      cursor: pointer;
      width: 50px;
    }

    .remove {
      cursor: pointer;
      margin-right: 15px;
      width: 50px;
    }
  }
}
