.curator {
  h3 {
    margin-top: 1.1em;
    margin-bottom: 1em;
  }

  .curator-photo {
    float: right;
    margin-left: 1em;
    height: 80px;
  }
}
