.trix_editor_wrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  border: solid 1px @color-team-yellow;
  border-radius: 4px;
}

.trix_editor_wrapper trix-toolbar {
  flex: 0;
}

.trix_editor_wrapper trix-editor {
  flex: 1;
  overflow-y: scroll;
  padding: 10px 10px 7px;
  transition: background 0.2s;
  background: white;
  color: @color-dark-grey;
  font-size: 14px;
  line-height: 1.5rem;
  border: none;
  box-shadow: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: solid 1px @color-team-yellow;

  &.error {
    background: #ffd2cc;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    // border: solid 1px @color-team-yellow;
    background: #fffcf8;

    &.error {
      background: #ffc6bf;
    }
  }
}

/* Force scroll bar to be shown in OSX */
.trix_editor_wrapper trix-editor::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.trix_editor_wrapper trix-editor::-webkit-scrollbar-thumb {
  border-radius: 4px;
  // background-color: rgba(0,0,0,.5);
  border: none;
  background: #fff9ef;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.trix_editor_wrapper trix-editor:empty:not(:focus)::before {
  // color: #a3a3a3;
  color: #a3a3a3;
}

.trix_editor_wrapper:not(.dont-hide-toolbar) trix-toolbar {
  display: none;
}

.trix_editor_wrapper.focused trix-toolbar {
  display: block;
}

.trix_editor_wrapper.toggle-toolbar-visiblity trix-toolbar {
  visibility: hidden;
}

.trix_editor_wrapper.toggle-toolbar-visiblity.focused trix-toolbar {
  visibility: visible;
}

.trix_editor_wrapper:not(.enable-headings) trix-toolbar .button_groups .button_group.block_tools .heading-1,
.trix_editor_wrapper:not(.enable-headings) trix-toolbar .button_groups .button_group.block_tools .heading-2,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.block_tools .nesting-level,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.text_tools .italic,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.text_tools .strike,
.trix_editor_wrapper:not(.enable-links) trix-toolbar .button_groups .button_group.text_tools .link,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.block_tools .quote,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.block_tools .code,
.trix_editor_wrapper trix-toolbar .button_groups .button_group.block_tools .block-level {
  display: none;
}

trix-editor h1,
trix-editor h2,
.trix h1,
.trix h2 {
  line-height: 3rem;
}

trix-editor div,
.trix div,
trix-editor li,
.trix li {
  line-height: 1.7rem;
}

trix-editor strong,
.trix strong {
  font-weight: 900;
}

trix-editor ul,
.trix ul {
  list-style-type: disc;
  padding-left: 30px;
}

trix-editor ol,
.trix ol {
  list-style-type: decimal;
  padding-left: 30px;
}

.trix_editor_wrapper trix-toolbar {
  height: 36px;

  .button_groups {
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #fff9ed;
  }

  .button_group {
    margin: 0;
    border: none;
    border-radius: 2px;
    margin-right: 0;

    button,
    input[type=button] {
      border: none;
      background: none;
      height: 26px;
      width: 30px;

      &.list.bullets {
        border-left: 0;
      }

      &.active {
        background: #ffdea6;
        border-radius: 2px;
      }

      &::before {
        background-size: 22px;
      }

      &.bold::before {
        background-size: 22px;
      }
    }

    button:not(:first-child),
    input[type=button]:not(:first-child) {
      border-left: none;
    }
  }

  button.attach::before {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA%2FPjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAxNiIgd2lkdGg9IjI0cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZXNjLz48ZGVmcy8%2BPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSI%2BPGcgZmlsbD0iIzAwMDAwMCIgaWQ9IkNvcmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjYuMDAwMDAwLCAtNDYuMDAwMDAwKSI%2BPGcgaWQ9ImJhY2t1cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTI2LjAwMDAwMCwgNDYuMDAwMDAwKSI%2BPHBhdGggZD0iTTE5LjQsNiBDMTguNywyLjYgMTUuNywwIDEyLDAgQzkuMSwwIDYuNiwxLjYgNS40LDQgQzIuMyw0LjQgMCw2LjkgMCwxMCBDMCwxMy4zIDIuNywxNiA2LDE2IEwxOSwxNiBDMjEuOCwxNiAyNCwxMy44IDI0LDExIEMyNCw4LjQgMjEuOSw2LjIgMTkuNCw2IEwxOS40LDYgWiBNMTQsOSBMMTQsMTMgTDEwLDEzIEwxMCw5IEw3LDkgTDEyLDQgTDE3LDkgTDE0LDkgTDE0LDkgWiIgaWQ9IlNoYXBlIi8%2BPC9nPjwvZz48L2c%2BPC9zdmc%2B);
  }

  button.sup::before {
    background-size: 16px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAcCAMAAABMOI/cAAAAVFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAU4H24AAAAG3RSTlMAECAwP0BQX2BvcH+Aj5CfoK+wv8DP0N/g7/CN3MwKAAAApklEQVQYGb3B4VKCQBhA0bsQRLgqEpLsff/37HPGGYv1Z9M5/IH0cT6+U2s3wzWxt1iWVUf2Uk6wuvDSzYkX0kU7av2XDtROuraA4ZMwGGDUDN0b3g2QNgMUJ0DJedUtMWnJmcYHoC166tQBaHwgjOpVZyqLoSQqTVEHar3hTCVt3vXszXqbdEv81qtdU/TCT/mw6QyjejzwZCgJWA08GUZCa+BffQOAtRCjIoOTuQAAAABJRU5ErkJggg==);
  }

  button.sub::before {
    background-size: 16px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAcCAMAAABMOI/cAAAAUVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABcqRVCAAAAGnRSTlMAECAwP0BQYG9wf4CPkJ+gr7C/wM/Q3+Dv8DtSRt8AAACiSURBVHgB5ZBrasUgFAY/05pHzKM2sYmz/4UWxZagcDdw59eBwXMG9b4AfKdhAKgEg2TuSjgX4DbaITqnJx8RVpvfVUzACV4NB0A0rejiY5EZt2Uscw+wlYu57kzPSyl9WRt9gDXNHq49NydcJwWOssimM1/648LLzTf43MwyK2E2sPovDZQvsT85EWDKOUWsEKxaBnCS/WxEZJcEqjBkXohfNhUP8gTXJ3QAAAAASUVORK5CYII=);
  }

  button.underline::before {
    background-size: 16px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAM1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACjBUbJAAAAEHRSTlMAEDBQYH+AkKCvsMDP0O/wvoI7qQAAAGVJREFUeAHtykkKAzEMBdEv9zyoXfc/bbQwARGTTQYI5O0KSu9C6PRPDv/BgUF3A3CkYQFOU2MnsKShELZ22EYoSlbCNZV4p4uwKjMncdPT4zA9mitNndVl4+7g+2j6ADq+PbzgBlZ9ETFujHz0AAAAAElFTkSuQmCC);
  }
}
