.search-container {
  .search-input {
    width: 100%;
    border: solid 1px #d8d8d8;
  }

  .btn {
    width: 100%;
    padding: 10px 0;
  }
}
