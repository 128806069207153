.navbar.navbar-dark {
  border-radius: 0;
  background: white;
  border: none;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  font-size: @base-font-size;

  .animation(navbar-in 1s);
  .keyframes(~'navbar-in, from { opacity: 0; transform: translateY(-65px) } to { opacity: 1; transform: translateY(0) }');

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-nav {
    flex-direction: row;
  }

  .navbar-header {
    height: 60px;
  }

  .icon-navbar {
    font-size: 20px;
  }

  .selected { color: @color-dark-grey !important; }

  .navbar-label {
    margin-left: 10px; /* rtl:ignore */
    margin-right: 10px; /* ltr:ignore */
    margin-top: 3px;
    position: relative;

    &.activity,
    &.messages,
    &.me {
      display: none;
    }
  }

  .navbar-group {
    display: inline-block;
  }

  .navbar-direction {
    display: inline-block;
  }

  .navbar-right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }

  .navbar-toggler {
    margin: 13px 15px 12px;
    height: 35px;
    align-items: center;
  }

  @media (max-width: 768px) {
    .navbar-toggler {
      display: flex;
    }

    .navbar-nav.navbar-right {
      .search-btn {
        display: contents;
        height: auto;
      }

      // .navbar-label {
      //   display: none;
      // }

      .navbar-direction {
        flex-direction: row;
      }

      // .selected {
      //   .navbar-label {
      //     display: inline-block;
      //   }
      // }
    }
  }

  /*
  .collapse {
    display: block;
  }

  @media (max-width: 768px) {
    .main-container .main-content {
      padding-bottom: 10em;
    }

    .navbar-toggler.collapsed {
      display: none;
    }

    .navbar-collapse.collapse {
      position: fixed;
      bottom: 0;
      left: 0;
      background: white;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      .nav.navbar-nav.navbar-right {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        border-top: solid 1px #d8d8d8;
      }

      .search-btn {
        padding: 10px 5px;
        position: fixed;
        right: 10px;
        top: 0;
      }

      .session-btn, .journey-btn, .activity-btn, .profile-btn, .workbook-btn, .course-details-btn, .messaging-btn, .publish-btn, .messages-btn {
        height: auto;
        padding: 5px 5px 0;

        .icon-navbar {
          font-size: 22px;
          display: block;
          margin-bottom: 3px;
        }

        .navbar-label {
          margin-left: 0;
          top: 0;
          position: relative;
          font-size: 12px;
        }
      }

      .activity-btn, .messages-btn, .profile-btn {
        .icon-navbar {
          margin-bottom: 6px;
        }
        .navbar-label {
          display: block;
        }
      }
    }
  }

  @media (max-width: 414px) {
    .navbar-collapse.collapse {
      .workbook-btn {
        display: none;
      }
    }
  }
  */

  // Left icons
  .progress-btn,
  .course-btn,
  .admin-btn,
  .gnowbefy-btn,
  .new-course-btn {
    height: 60px;
    width: 60px;
    cursor: pointer;
    display: inline-block;
    padding: 20px;
    transition: background-color 0.05s;

    .navbar-label {
      margin-right: 0;
    }

    &:hover,
    &:focus,
    &.selected {
      background-color: @color-dark-grey;
      color: #fff;
    }
  }

  .progress-btn {
    // position: absolute;
    @apply absolute right-0;

    .icon-navbar::before { content: "\e91e"; }

    &.selected {
      color: white !important;
    }
  }

  @media (min-width: 768px) {
    .progress-btn {
      @apply relative right-auto;
    }
  }

  .course-btn {
    position: relative;
    .icon-navbar::before { content: "\e975"; }

    &:hover,
    &:focus,
    &.selected {
      background-color: @color-deadyellow;
      color: @color-dark-grey !important;
    }
  }

  .gnowbefy-btn {
    width: auto;
    position: relative;
    display: flex;

    .icon-navbar::before { content: "\e966"; }
    .navbar-label { margin-top: 0; }

    &:hover,
    &:focus,
    &.selected {
      background-color: @color-personal-blu;
      color: @color-dark-grey !important;
    }
  }

  .new-course-btn {
    width: auto;
    position: relative;
    display: flex;

    &:hover {
      background: @color-team-yellow;
      color: @color-dark-grey;
    }

    .navbar-label { margin-top: 0; }
    .icon-navbar::before { content: "\e926"; }
  }

  .admin-btn {
    padding-left: 20px;
    .icon-navbar::before { content: "\e911"; }
  }

  // Right icons
  .session-btn,
  .journey-btn,
  .activity-btn,
  .profile-btn,
  .help-btn,
  .search-btn,
  .workbook-btn,
  .course-details-btn,
  .messaging-btn,
  .publish-btn,
  .messages-btn,
  .goodiebag-btn,
  .more-nav-btn,
  .completion-btn {
    // height: 60px; // 60px - 20px navbar-right padding
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.05s;

    &.mobile {
      padding: 0;

      &:hover {
        color: @color-dark-grey;
      }
    }

    &:hover {
      color: darken(@color-personal-blu, 20%);
    }
  }

  .search-btn {
    // padding: 10px 5px;
    // display: inline-block;
    // height: 60px;

    &.active {
      .search-bar {
        background: #f6f6f6;
        border-color: #d5d5d5;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      }
    }

    .search-bar {
      position: relative;
      background: #fff;
      border-radius: 4px;
      border: solid 1px transparent;
      cursor: text;
      transition: background-color 0.4s;

      form {
        .input-container {
          padding: 10px 15px 8px;
          overflow: hidden;
          position: relative;

          .icon-navbar {
            float: left;
            margin-top: -1px;
          }

          input {
            border: 0 none;
            transition: width 0.4s ease 0s;
            width: 60px;
            padding: 0;
            font-size: 1rem;
            background: transparent none repeat scroll 0% 0%;

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: @color-medium-well;
            }

            &::-moz-placeholder {
              color: @color-medium-well;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: @color-medium-well;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
              color: @color-medium-well;
            }
          }

          .icon-x {
            font-size: 12px;
            color: #bdbdbd;
            cursor: pointer;
          }
        }

        .results {
          display: block;
          padding: 5px 0 0;
          background: #f6f6f6;
          border-top: solid 1px #d5d5d5;
          // text-align: left;
          border-radius: 4px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          max-width: 322.5px;
          margin-bottom: 0;

          li {
            padding: 10px 10px 8px 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            .contentType {
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 10px;
              margin-right: 10px;

              &.qa { background-color: @color-qa }
              &.text { background-color: @color-text }
              &.photo { background-color: @color-photo }
              &.multiple_choice { background-color: @color-multiple_choice }
              &.assessment { background-color: @color-assessment }
              &.rating { background-color: @color-rating }
              &.link { background-color: @color-link }
              &.external { background-color: @color-external }
              &.image { background-color: @color-image }
              &.video { background-color: @color-video }
              &.audio { background-color: @color-audio }
              &.upload { background-color: @color-upload }
              &.certificate { background-color: @color-certificate }
              &.discussion { background-color: @color-discussion }
              &.notes { background-color: @color-note }
              &.quote { background-color: @color-brown-light; }
              &.chapter { background-color: @color-dark-grey; }
            }

            &.placeholder {
              text-align: center;
              padding-bottom: 10px;
            }

            &.loading {
              text-align: center;
              display: block;
              padding-bottom: 15px;

              img {
                height: 50px;
              }
            }

            &.header {
              background: #efefef;
              font-size: 12px;
              font-weight: bold;
              padding: 9px 10px 5px 15px;
              letter-spacing: 0.4px;
            }

            &.all-results {
              border-top: solid 1px #d5d5d5;
              font-weight: bold;
              padding: 13px 15px 10px;
              background: #f6f6f6;

              &:hover {
                background: #fff7da;
                cursor: pointer;
              }
            }

            &.link {
              display: flex;
              align-items: first baseline;

              small {
                color: #888;
              }

              .result-title {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              &:hover {
                background: #fff7da;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .icon-navbar {
      // font-size: 20.5px;
      // margin-right: 10px;
      &::before { content: "\e91f"; }
    }

    &.selected .icon-navbar::before { content: "\e97a"; }
    .navbar-label { margin-left: 5px; }
  }

  .session-btn {
    .icon-navbar::before { content: "\e91d"; }
    &.selected .icon-navbar::before { content: "\e91c"; }
  }

  .journey-btn {
    .icon-navbar::before { content: "\e93d"; }
    &.selected .icon-navbar::before { content: "\e93c"; }
  }

  .course-details-btn {
    .icon-navbar::before { content: "\e932"; }
    &.selected .icon-navbar::before { content: "\e967"; }
  }

  .workbook-btn {
    .icon-navbar::before { content: "\e95c"; }
    &.selected .icon-navbar::before { content: "\e95b"; }
  }

  .goodiebag-btn {
    .icon-navbar::before { content: "\e91a"; }
    &.selected .icon-navbar::before { content: "\e919"; }
  }

  .publish-btn {
    .icon-navbar::before { content: "\e91b"; }
  }

  .messaging-btn {
    .icon-navbar::before { content: "\e95f"; }
    &.selected .icon-navbar::before { content: "\e95e"; }
  }

  .completion-btn {
    .icon-navbar::before { content: "\e978"; }
    &.selected .icon-navbar::before { content: "\e977"; }
  }

  .messages-btn {
    position: relative;

    .icon-navbar::before {
      display: inline-block;
      content: "\e94f";
      .scaleX(-1);
    }

    &.selected .icon-navbar::before {
      display: inline-block;
      content: "\e950";
      .scaleX(-1);
    }
  }

  .assessments-btn {
    .icon-navbar::before { content: "\E9B8"; }
  }

  .activity-btn {
    position: relative;
    .icon-navbar::before { content: "\e95f"; }
    &.selected .icon-navbar::before { content: "\e95e"; }
  }

  .profile-btn {
    margin-right: 0.5em;
    .icon-navbar::before { content: "\e90a"; }
    &.selected .icon-navbar::before { content: "\e909"; }
  }

  .help-btn {
    margin-right: 0.5em;
    color: @color-dark-grey;
    .icon-navbar::before { content: "\e925"; }
    &.selected .icon-navbar::before { content: "\e925"; }
  }

  .top-progress-bar {
    height: 5px;
    width: 100%;
    background-color: @color-brown-lighter;

    .current-progress {
      height: 5px;
      background-color: @color-brown;
    }
  }

  .current-session-progress {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 24px;
    color: @color-undone;

    .icon-dot { padding: 0 5px }
  }
}

.no-cloud-connection {
  width: 170px;
  position: fixed;
  top: 1rem;
  left: 50%;
  margin-left: -85px;
  background-color: @color-primary-red;
  text-align: center;
  color: white;
  border-radius: 20px;
  padding: 8px 10px 5px;
  z-index: 9999;
}
