.goodie-bag {
  position: relative;

  .goodiebag {
    .goodiebag-wrapper {
      margin-top: 1em;
      background: white;
      padding: 2em;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

      .header {
        text-align: center;

        .header-logo {
          max-width: 200px;
          margin: 10px auto;
          display: table;
        }

        .title {
          color: @color-dark-grey;
          line-height: 1.3;
          font-size: 21px;
          font-weight: 400;
          margin: 15px 0;
          text-align: center;
        }
      }

      .goodiebag-url {
        display: inline-block;
      }

      .description {
        margin: 30px 0;
      }

      .files {
        text-align: center;

        h4 {
          margin-top: 2em;
          font-family: 'NotoSerif', 'CaslonTwoTwentyFour-Bold', Georgia, Times, 'Times New Roman', serif;
          color: @color-dark-grey;
          font-weight: normal;
          text-transform: uppercase;
          font-size: 1rem;
        }

        ul {
          width: 500px;
          overflow: hidden;
          padding: 0;
          margin: 0 auto;
          list-style: none;

          li {
            line-height: 22px;
            margin-top: 10px;

            a {
              color: @color-medium-well;

              .icon-trash-alt-light {
                color: darken(@color-primary-red, 20%);
              }
            }

            input {
              width: 400px;
              height: auto;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: inline-block;
              text-align: center;
              border-radius: 4px;
            }

            a.goodie-link {
              width: 400px;
              height: auto;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 16px;
              display: inline-block;
              background: white;
              text-align: center;
              padding: 10px;
              border-radius: 5px;
              color: @color-wrong;
              border: solid 1px @color-wrong;
              font-family: "NotoSans", "NeueHaasUnicaPro", "Helvetica Neue", Helvetica, Arial, sans-serif;
            }
          }
        }

        .add-goodie {
          margin-top: 1em;
        }
      }
    }
  }
}
